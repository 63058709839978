import "normalize.css";
import { func, instanceOf } from "prop-types";
import React from "react";
import Immutable from "immutable";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./header";
import Home from "./home";
import ErrorNotificationContainer from "./error_notification";
import AppActions from "../actions/app_actions";
import AppStore from "../stores/app_store";
import connectStores from "./enhancers/connect_stores";
import ProtectedRoute from "./protected_route";
import SearchResults from "./search_results";
import MarkerStateDetails from "./marker_state_details";
import DraftMarkerStateDetails from "./draft_marker_state_details";
import AdminScreen from "./admin_screen";
import DraftMarkerStates from "./draft_marker_states";
import Footer from "./footer";
import Outcomes from "./outcomes/outcomes";
import "../styles/app.styl";

const storeConnectors = {
  AppStore(Store) {
    return {
      appErrors: Store.getAppErrors(),
    };
  },
};

class AppComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.i18n = props.i18n;
    this.getChildContext = this.getChildContext.bind(this);
  }

  getChildContext() {
    return {
      i18n: this.i18n,
    };
  }

  componentWillMount() {
    AppActions.checkAuth();
  }

  render() {
    return (
      <Router>
        <div className="application-container">
          <ErrorNotificationContainer errors={this.props.appErrors} />
          <Header />
          <div className="main-content-with-footer">
            <div className="main-content-container">
              <Route component={Home} exact path="/" />
              <Route component={SearchResults} path="/search" />
              <Route component={MarkerStateDetails} path="/marker-states/:id" />
              <Route component={AdminScreen} path="/admin" />
              <ProtectedRoute exact component={Outcomes} path="/outcomes" />
              <ProtectedRoute exact component={DraftMarkerStates} path="/drafts" />
              <ProtectedRoute component={DraftMarkerStateDetails} path="/drafts/:id" />
            </div>
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

AppComponent.propTypes = {
  i18n: func.isRequired,
  appErrors: instanceOf(Immutable.List).isRequired,
};

AppComponent.childContextTypes = {
  i18n: func,
};

export { AppComponent };
export default connectStores(AppComponent, AppStore, storeConnectors);
