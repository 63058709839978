import React from "react";
import Immutable from "immutable";
import { func, bool, string, instanceOf, object } from "prop-types";
import connectStores from "./enhancers/connect_stores";
import MarkerStateItem from "./marker_state_item";
import MarkerStateEditButtons from "./marker_state_edit_buttons";
import MarkerDetailsTab from "./marker_details_tab";
import MarkerStateActions from "../actions/marker_state_actions";
import DraftsActions from "../actions/drafts_actions";
import MarkerStateStore from "../stores/marker_state_store";
import ConfirmationlDialog from "./common/confirmation_dialog";
import AppStore from "../stores/app_store";
import Spinner from "./spinner";
import Tabs from "./common/tabs";
import Tab from "./common/tab";
import alt from "../alt";

const storeConnector = {
  MarkerStateStore(Store) {
    return {
      fetching: Store.isFetching(),
      markerState: Store.getMarkerState(),
      activeTab: Store.getActiveTab(),
      editingOverview: Store.isBlockInEditMode("overview"),
      editingImportance: Store.isBlockInEditMode("importance"),
      editingUtility: Store.isBlockInEditMode("utility"),
      wasEdited: Store.wasEdited(),
      wasDeleted: Store.wasDeleted(),
    };
  },
  AppStore(Store) {
    return {
      editable: Store.isLoggedIn(),
    };
  },
};

export class MarkerStateDetailsComponent extends React.Component {
  static handleEditCancel = () => MarkerStateActions.cancelEdition();

  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = {
      showDeleteConfirmation: false,
      showUnsavedChangesConfirmation: false,
    };
    this.getTabProps = this.getTabProps.bind(this);
    this.handleEditSave = this.handleEditSave.bind(this);
    this.handleMarkerStateDelete = this.handleMarkerStateDelete.bind(this);
    this.toggleDeleteConfirmation = this.toggleDeleteConfirmation.bind(this);
    this.toggleUnsavedChangesConfirmation = this.toggleUnsavedChangesConfirmation.bind(this);
    this.verifyUnsavedChanges = this.verifyUnsavedChanges.bind(this);
  }

  navigateBack() {
    if (this.navigatedFromList()) {
      this.props.history.goBack();
    } else {
      window.location.href = window.location.origin;
    }
  }

  componentDidMount() {
    const { markerState } = this.props;
    // do nothing if marker state data is already available
    if (markerState && markerState.get("@id") === this.props.match.params.id) return;
    this.getMarkerStateDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      return this.getMarkerStateDetails();
    }
    if (this.props.wasDeleted) {
      // navigate away. Either back to the list of marker states or to the main page
      this.navigateBack();
    }
    if (prevProps.fetching && !this.props.fetching && !this.props.markerState) {
      this.navigateBack();
    }
  }

  componentWillUnmount() {
    alt.recycle("MarkerStateStore");
  }

  onTabChange = (newTab) => {
    MarkerStateActions.changeTab(newTab);
  };

  getTabProps(ratingName) {
    const ratingData = this.props.markerState.get(ratingName);
    return {
      symptoms: ratingData.get("symptoms"),
      timeHorizon: ratingData.get("timeHorizon"),
      testingAndTreatment: ratingData.get("testingAndTreatment"),
      consequences: ratingData.get("consequences"),
    };
  }

  getMarkerStateDetails() {
    const mId = this.props.match.params.id;
    return mId === "new"
      ? DraftsActions.prepareMarkerStateDraft()
      : MarkerStateActions.fetch(mId, this.props.isDraft);
  }

  handleEditSave() {
    MarkerStateActions.saveChanges(this.props.isDraft);
  }

  handleMarkerStateDelete() {
    this.toggleDeleteConfirmation();
    MarkerStateActions.deleteMarkerState(this.props.markerState.get("@id"), this.props.isDraft);
  }

  navigatedFromList() {
    const { state: locationState } = this.props.location;
    return locationState && locationState.fromList;
  }

  verifyUnsavedChanges() {
    const { wasEdited, editingImportance, editingOverview, editingUtility } = this.props;
    if (wasEdited || editingImportance || editingOverview || editingUtility) {
      this.toggleUnsavedChangesConfirmation();
    } else {
      this.props.history.goBack();
    }
  }

  toggleDeleteConfirmation() {
    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
    });
  }

  toggleUnsavedChangesConfirmation() {
    this.setState({
      showUnsavedChangesConfirmation: !this.state.showUnsavedChangesConfirmation,
    });
  }

  renderBackButton() {
    // render back button only if navigated from list
    if (this.navigatedFromList()) {
      return (
        <button className="btn btn-primary btn-back" onClick={this.verifyUnsavedChanges}>
          {this.i18n("actions.back")}
        </button>
      );
    }
    return null;
  }

  render() {
    const { markerState, fetching, editable, isDraft } = this.props;
    const { editingOverview, editingImportance, editingUtility } = this.props;
    const isNewDraft = this.props.match.params.id === "new";

    return (
      // FIXME: there is a very long discussion here
      // https://github.com/reactjs/react-redux/issues/210
      // The main issue is that we cannot guarantee that dispatch will be run before
      // componentWill/DidMount - this mean that fetching is false and we don't have any data yet
      // This should be thoroughly rethinked and addressed
      fetching || !markerState ? (
        <Spinner />
      ) : (
        <div className="marker-states-details">
          {this.renderBackButton()}
          <div className="ms-container marker-states-container">
            <div className="marker-states-details-header">
              <MarkerStateItem
                id={markerState.get("@id")}
                name={markerState.getIn(["forOutcome", "name"])}
                context={markerState.get("context")}
                author={markerState.get("author")}
                background={markerState.get("background", "")}
                dateCreated={markerState.get("dateCreated")}
                editable={editable}
                inEditMode={editingOverview}
                noLink
              />
            </div>
            <div className="details-tabs">
              <Tabs activeTab={this.props.activeTab} changeTab={this.onTabChange}>
                <Tab tabName="importance" tabLabel={this.i18n("marker_details.importance_rating")}>
                  <MarkerDetailsTab
                    {...this.getTabProps("importance")}
                    ratingType="importance"
                    editable={editable}
                    inEditMode={editingImportance}
                  />
                </Tab>
                <Tab tabName="utility" tabLabel={this.i18n("marker_details.utility_rating")}>
                  <MarkerDetailsTab
                    {...this.getTabProps("utility")}
                    ratingType="utility"
                    editable={editable}
                    inEditMode={editingUtility}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
          {editable ? (
            <MarkerStateEditButtons
              isDraft={isDraft}
              isNewDraft={isNewDraft}
              wasEdited={this.props.wasEdited}
              onCancel={MarkerStateDetailsComponent.handleEditCancel}
              onDelete={this.toggleDeleteConfirmation}
              onSave={this.handleEditSave}
            />
          ) : null}
          <div className="confirmations-container">
            {this.state.showDeleteConfirmation ? (
              <ConfirmationlDialog
                confirmationText={this.i18n("confirmations.delete_marker_state")}
                onCancel={this.toggleDeleteConfirmation}
                onConfirm={this.handleMarkerStateDelete}
              />
            ) : null}
            {this.state.showUnsavedChangesConfirmation ? (
              <ConfirmationlDialog
                confirmationText={this.i18n("confirmations.unsaved_changes")}
                onCancel={this.toggleUnsavedChangesConfirmation}
                onConfirm={this.props.history.goBack}
              />
            ) : null}
          </div>
        </div>
      )
    );
  }
}

MarkerStateDetailsComponent.contextTypes = { i18n: func };

MarkerStateDetailsComponent.propTypes = {
  fetching: bool.isRequired,
  markerState: instanceOf(Immutable.Map),
  activeTab: string.isRequired,
  wasEdited: bool.isRequired,
  wasDeleted: bool.isRequired,
  editable: bool,
  editingOverview: bool,
  editingImportance: bool,
  editingUtility: bool,
  isDraft: bool,
  // ReactRouter props
  location: object.isRequired, // eslint-disable-line react/forbid-prop-types
  match: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

MarkerStateDetailsComponent.defaultProps = {
  markerState: Immutable.Map(),
  editable: false,
  editingOverview: false,
  editingImportance: false,
  editingUtility: false,
  isDraft: false,
};

export default connectStores(
  MarkerStateDetailsComponent,
  [MarkerStateStore, AppStore],
  storeConnector
);
