import React from "react";
import { func, bool, instanceOf, number } from "prop-types";
import Immutable from "immutable";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import connectStores from "./enhancers/connect_stores";
import DraftsActions from "../actions/drafts_actions";
import DraftsStore from "../stores/drafts_store";
import DraftMarkerState from "./draft_marker_state";
import Spinner from "./spinner";
import { parseQueryString } from "../utils";
import MarkerStatesPagination from "./marker_states_pagination";

const storeConnectors = {
  DraftsStore(Store) {
    return {
      isFetching: Store.isFetching(),
      pubishingIds: Store.getPublishingIds(),
      markerStates: Store.getMarkerStates(),
      totalCount: Store.getTotalCount(),
    };
  },
};

class DraftMarkerStatesComponent extends React.Component {
  static getLinkToDraft = (draftId) => ({
    pathname: `/drafts/${draftId}`,
    state: { fromList: true },
  });

  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.handlePublishAll = this.handlePublishAll.bind(this);
    this.navigateToDraftEditing = this.navigateToDraftEditing.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.doFetch = this.doFetch.bind(this);
  }

  componentDidMount() {
    // debounce to avoid dispatch in the middle of dispatch
    return debounce(this.doFetch)();
  }

  componentDidUpdate(prevProps) {
    return this.props.location !== prevProps.location ? this.doFetch() : undefined;
  }

  doFetch() {
    if (this.props.isFetching) return;
    const params = parseQueryString(this.props.location.search);
    DraftsActions.fetch(params);
  }

  handleAddNew() {
    DraftsActions.prepareMarkerStateDraft();
    this.navigateToDraftEditing();
  }

  handlePublishAll() {
    return this.props.markerStates.map((ms) => ms.get("@id")).forEach(DraftsActions.publish);
  }

  navigateToDraftEditing() {
    return this.props.history.push({
      pathname: "/drafts/new",
      state: { fromList: true },
    });
  }

  renderMarkerStates() {
    const { markerStates, pubishingIds } = this.props;
    if (markerStates.isEmpty()) return null;

    return (
      <div className="ms-container drafts">
        {markerStates
          .map((ms) => {
            const msId = ms.get("@id");
            return (
              <DraftMarkerState
                author={ms.get("author")}
                context={ms.get("context")}
                dateCreated={ms.get("dateCreated")}
                id={msId}
                isBeingPublished={pubishingIds.includes(msId)}
                key={msId}
                linkToDraft={DraftMarkerStatesComponent.getLinkToDraft(msId)}
                name={ms.get("name")}
                onPublish={DraftsActions.publish}
              />
            );
          })
          .toList()}
      </div>
    );
  }

  render() {
    const { isFetching, totalCount } = this.props;
    return (
      <div className="drafts-container">
        <div className="ms-container drafts-title-block">
          <div className="text-container">
            <div className="title-text">{this.i18n("drafts.title")}</div>
            <div className="help-text">{this.i18n("drafts.help_text")}</div>
          </div>
          <div className="buttons-container">
            <Link className="btn btn-primary outcomes-link" to="outcomes">
              {this.i18n("outcomes_list.outcomes_list")}
            </Link>
            <button className="btn btn-danger" onClick={this.handleAddNew}>
              {this.i18n("add_new_marker_state")}
            </button>
          </div>
        </div>
        {isFetching ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          this.renderMarkerStates()
        )}
        <MarkerStatesPagination itemsCount={totalCount} />
        {totalCount > 1 && !isFetching ? (
          <button className="btn btn-primary publish-all" onClick={this.handlePublishAll}>
            {this.i18n("drafts.publish_all")}
          </button>
        ) : null}
      </div>
    );
  }
}

DraftMarkerStatesComponent.contextTypes = { i18n: func };

DraftMarkerStatesComponent.propTypes = {
  isFetching: bool.isRequired,
  markerStates: instanceOf(Immutable.List).isRequired,
  pubishingIds: instanceOf(Immutable.List).isRequired,
  totalCount: number.isRequired,
};

export { DraftMarkerStatesComponent };
export default connectStores(DraftMarkerStatesComponent, DraftsStore, storeConnectors);
