import React, { Component } from "react";
import Immutable from "immutable";
import { bool, func, instanceOf } from "prop-types";
import OutcomeListItem from "./outcome_list_item";

class OutcomesList extends Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    const { outcomes, editingOutcome, isSaving, isDeleting, outcomesStats } = this.props;
    if (!outcomes) return null;
    const editingOutcomeId = editingOutcome.get("@id");

    return (
      <div className="outcomes-list">
        {outcomes.isEmpty() ? (
          <div>{this.i18n("search.nothing_to_list")}</div>
        ) : (
          outcomes
            .map((outcome) => {
              const outcomeId = outcome.get("@id");
              const isEditing = outcomeId === editingOutcomeId;

              return (
                <OutcomeListItem
                  isDeleting={isDeleting}
                  isEditing={isEditing}
                  isSaving={isSaving}
                  key={outcomeId}
                  outcome={isEditing ? editingOutcome : outcome}
                  stats={outcomesStats.get(outcomeId)}
                />
              );
            })
            .toList()
        )}
      </div>
    );
  }
}

OutcomesList.contextTypes = { i18n: func };

OutcomesList.propTypes = {
  outcomes: instanceOf(Immutable.List),
  editingOutcome: instanceOf(Immutable.Map).isRequired,
  isSaving: bool.isRequired,
  isDeleting: bool.isRequired,
  outcomesStats: instanceOf(Immutable.Map).isRequired,
};

OutcomesList.defaultProps = {
  outcomes: null,
};

export default OutcomesList;
