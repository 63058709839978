import React from "react";
import { func } from "prop-types";

class GdtBox extends React.Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    return (
      <div className="ms-container card-with-button gdt-box">
        <h2>GRADEpro GDT</h2>
        <div>{this.i18n("gdt_description")}</div>
        <a
          className="btn-danger"
          href="https://gradepro.org/"
          rel="noopener noreferrer"
          target="_blank"
        >
          {this.i18n("learn_more")}
        </a>
      </div>
    );
  }
}

GdtBox.contextTypes = { i18n: func };

export default GdtBox;
