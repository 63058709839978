import alt from '../alt';
import { msApi, getQueryString } from '../utils';
import { paginationLimit } from '../config';

class SearchActions {
  constructor() {
    this.generateActions(
      'searchSuccess',
      'searchError',
    );
  }

  search(searchParams) {
    const queryString = getQueryString('msSearch', searchParams);
    const queryParams = {
      start: searchParams.start || 0,
      limit: searchParams.limit || paginationLimit,
    };

    return (dispatch) => {
      dispatch();
      msApi.search(queryString, queryParams)
        .then(this.searchSuccess)
        .catch(this.searchError);
    };
  }
}

export default alt.createActions(SearchActions);
