import Immutable from 'immutable';
import alt from '../alt';
import { reportError } from '../utils';
import SearchActions from '../actions/ms_search_actions';
import immutableStoreConfig from './configs/immutable_store';

class MsSearchStore {
  static config = immutableStoreConfig;

  constructor() {
    const initialState = {
      meta: {
        isSearching: false,
      },
      searchResults: {
        markerStates: [],
        totalCount: 0,
      },
    };
    this.state = Immutable.fromJS(initialState);
    this.exportPublicMethods({
      isSearching: this.isSearching,
      getSearchResults: this.getSearchResults,
    });
    this.bindActions(SearchActions);
  }

  onSearch() {
    return this.setState(this.state.setIn(['meta', 'isSearching'], true));
  }

  onSearchSuccess(results) {
    return this.setState(this.state.withMutations((state) => {
      state.setIn(['meta', 'isSearching'], false);
      state.set('searchResults', Immutable.fromJS(results));
    }));
  }

  onSearchError(error) {
    reportError(error);
    return this.setState(this.state.setIn(['meta', 'isSearching'], false));
  }

  isSearching() {
    return this.state.getIn(['meta', 'isSearching']);
  }

  getSearchResults() {
    return this.state.get('searchResults');
  }
}

export default alt.createStore(MsSearchStore, 'MsSearchStore');
