import alt from '../alt';
import { draftsApi } from '../utils';

class DraftsActions {
  constructor() {
    this.generateActions(
      'fetchSuccess',
      'fetchError',
      'publishSuccess',
      'publishError',
      'prepareMarkerStateDraft',
    );
  }

  fetch(params = {}) {
    return (dispatch) => {
      dispatch();
      return draftsApi.fetch(params)
        .then(this.fetchSuccess)
        .catch(this.fetchError);
    };
  }

  publish(draftId) {
    return (dispatch) => {
      dispatch(draftId);
      return draftsApi.publish(draftId)
        .then(() => this.publishSuccess(draftId))
        .catch(error => this.publishError({ draftId, error }));
    };
  }
}

export default alt.createActions(DraftsActions);
