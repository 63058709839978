import Immutable from "immutable";
import i18next from "i18next";
import alt from "../alt";
import { formatIso8601Date, generateGUID, reportError, escapeSearchHighlight } from "../utils";
import MakerStateActions from "../actions/marker_state_actions";
import DraftsActions from "../actions/drafts_actions";
import immutableStoreConfig from "./configs/immutable_store";

const INIT_STATE = Immutable.fromJS({
  meta: {
    fetching: false,
    activeTab: "importance",
    wasEdited: false,
    wasDeleted: false,
    editingBlocks: {
      overview: false,
      importance: false,
      utility: false,
    },
    editedBlocks: {
      overview: false,
      importance: false,
      utility: false,
    },
  },
  snapshots: {
    initialMarkerState: null,
    overview: null,
    importance: null,
    utility: null,
  },
  markerState: null,
});

const getEmptyMarkerState = (draftId) =>
  Immutable.fromJS({
    reviewer: "",
    forOutcome: { "@id": "", name: "" },
    keywords: [],
    author: "",
    utility: {
      "@type": "MarkerStateUtility",
      symptoms: i18next.t("empty_marker_state.utility.symptoms_text"),
      timeHorizon: "...",
      testingAndTreatment: i18next.t("empty_marker_state.utility.testing_and_treatment_text"),
      consequences: i18next.t("empty_marker_state.utility.consequences_text"),
    },
    context: "",
    importance: {
      "@type": "MarkerStateImportance",
      symptoms: i18next.t("empty_marker_state.utility.symptoms_text"),
      timeHorizon: "...",
      testingAndTreatment: i18next.t("empty_marker_state.importance.testing_and_treatment_text"),
      consequences: i18next.t("empty_marker_state.importance.consequences_text"),
    },
    version: "1",
    dateCreated: formatIso8601Date(Date.now()),
    "@type": "MarkerState",
    "@id": draftId,
  });

class MarkerStateStore {
  static config = immutableStoreConfig;

  constructor() {
    this.on("init", () => {
      this.state = INIT_STATE;
    });
    this.state = INIT_STATE;
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getMarkerState: this.getMarkerState,
      getActiveTab: this.getActiveTab,
      isBlockInEditMode: this.isBlockInEditMode,
      wasEdited: this.wasEdited,
      wasDeleted: this.wasDeleted,
    });
    this.bindActions(MakerStateActions);
    this.bindListeners({
      onPrepareMarkerStateDraft: DraftsActions.prepareMarkerStateDraft,
    });
  }

  takeSnapshot = (name, state) => {
    const markerState = state.get("markerState");
    let snapshot;
    if (name === "overview") {
      snapshot = Immutable.fromJS({
        author: markerState.get("author"),
        context: markerState.get("context"),
        dateCreated: markerState.get("dateCreated"),
        forOutcome: markerState.get("forOutcome"),
        reviewer: markerState.get("author"),
      });
    } else {
      snapshot = markerState.get(name);
    }
    return state.setIn(["snapshots", name], snapshot);
  };

  restoreFromSnaphot = (name, state) => {
    const snapshot = state.getIn(["snapshots", name]);

    return name === "overview"
      ? state.mergeIn(["markerState"], snapshot)
      : state.setIn(["markerState", name], snapshot);
  };

  resetEditedBlocks = (state) => {
    state.setIn(
      ["meta", "editedBlocks"],
      Immutable.Map({
        overview: false,
        importance: false,
        utility: false,
      })
    );
  };

  // outcome update can be different. It can be change of its name text or it can be setting both
  // name text and outcome id (e.g. from outcome search). If text is changed and there is already
  // some id, the id has to be nulified since the text it is related to is altered
  doOutcomeUpdate = (data, state) => {
    const { id, name } = data;
    return state.setIn(
      ["markerState", "forOutcome"],
      Immutable.Map({
        "@id": id,
        name: escapeSearchHighlight(name),
      })
    );
  };

  onFetch() {
    this.setState(this.state.setIn(["meta", "fetching"], true));
  }

  onFetchSuccess({ markerState }) {
    const immutableMarkerState = Immutable.fromJS(markerState);
    this.setState(
      this.state.withMutations((state) => {
        state.setIn(["meta", "fetching"], false);
        state.set("markerState", immutableMarkerState);
        state.setIn(["snapshots", "initialMarkerState"], immutableMarkerState);
      })
    );
  }

  onFetchError() {
    this.setState(this.state.setIn(["meta", "fetching"], false));
  }

  onChangeTab(newActiveTab) {
    const currentTab = this.state.getIn(["meta", "activeTab"]);
    if (currentTab === newActiveTab) return;

    this.setState(
      this.state.withMutations((state) => {
        state.setIn(["meta", "activeTab"], newActiveTab);
        // turn off editing of the tab navigating from
        if (this.isBlockInEditMode(currentTab)) {
          state.setIn(["meta", "editingBlocks", currentTab], false);
        }
      })
    );
  }

  onEditBlock(blockName) {
    this.setState(
      this.state.withMutations((state) => {
        state.setIn(["meta", "editingBlocks", blockName], true);
        this.takeSnapshot(blockName, state);
      })
    );
  }

  _cancelBlockEdition(blockName, state) {
    if (state.getIn(["meta", "editingBlocks", blockName])) {
      this.restoreFromSnaphot(blockName, state);
      state.setIn(["meta", "editingBlocks", blockName], false);
      state.setIn(["meta", "editedBlocks", blockName], false);
    }
  }

  onCancelBlockEdition(blockName) {
    this.setState(
      this.state.withMutations((state) => {
        this._cancelBlockEdition(blockName, state);
      })
    );
  }

  _appyBlockEdition(blockName) {
    // simply set editing to false
    // no need to clear the snapshot as it will be updated on next edit
    return this.setState(this.state.setIn(["meta", "editingBlocks", blockName], false));
  }

  onApplyBlockEdition(blockName) {
    this._appyBlockEdition(blockName);
  }

  onCancelEdition() {
    return this.setState(
      this.state.withMutations((state) => {
        state.set("markerState", this.state.getIn(["snapshots", "initialMarkerState"]));
        this.resetEditedBlocks(state);
      })
    );
  }

  onSaveChanges() {
    ["overview", "importance", "utility"].forEach((blockName) => {
      this._appyBlockEdition(blockName);
    });
    this.setState(this.state.setIn(["meta", "fetching"], true));
  }

  // TODO: what really to do? Display a message or go to other page?
  onSaveChangesSuccess() {
    this.setState(
      this.state.withMutations((state) => {
        state.setIn(["meta", "fetching"], false);
        this.resetEditedBlocks(state);
        // updated markerstate snapshot
        state.setIn(["snapshots", "initialMarkerState"], state.get("markerState"));
      })
    );
  }

  // TODO: handle error
  onSaveChangesFailure(err) {
    reportError(err);
    this.setState(
      this.state.withMutations((state) => {
        state.setIn(["meta", "fetching"], false);
      })
    );
  }

  // ratingType - importance or utility
  onUpdateRatingDescription({ ratingType, key, value }) {
    this.setState(
      this.state.withMutations((state) => {
        state.setIn(["markerState", ratingType, key], value);
        state.setIn(["meta", "editedBlocks", ratingType], true);
      })
    );
  }

  onUpdateMarkerItem({ name, value }) {
    this.setState(
      this.state.withMutations((state) => {
        switch (name) {
          case "outcome":
            this.doOutcomeUpdate({ name: value, id: null }, state);
            break;
          case "author":
            state.setIn(["markerState", name], value);
            state.setIn(["markerState", "reviewer"], value);
            break;
          default:
            state.setIn(["markerState", name], value);
        }
        state.setIn(["meta", "editedBlocks", "overview"], true);
      })
    );
  }

  onUpdateOutcome(outcomeData) {
    this.setState(
      this.state.withMutations((state) => {
        this.doOutcomeUpdate(outcomeData, state);
        state.setIn(["meta", "editedBlocks", "overview"], true);
      })
    );
  }

  onDeleteSuccess() {
    this.setState(this.state.setIn(["meta", "wasDeleted"], true));
  }

  onPrepareMarkerStateDraft() {
    const draftId = generateGUID();
    const emptyMarkerState = getEmptyMarkerState(draftId);
    return this.setState(
      this.state.withMutations((state) => {
        state.setIn(["snapshots", "initialMarkerState"], emptyMarkerState);
        state.set("markerState", emptyMarkerState);
      })
    );
  }

  isFetching() {
    return this.state.getIn(["meta", "fetching"]);
  }

  getMarkerState() {
    return this.state.get("markerState");
  }

  getActiveTab() {
    return this.state.getIn(["meta", "activeTab"]);
  }

  isBlockInEditMode(blockName) {
    return this.state.getIn(["meta", "editingBlocks", blockName]);
  }

  isInEditMode() {
    // marker state is in edit mode if at least one block is in edit mode
    return this.state.getIn(["meta", "editingBlock"]).includes(true);
  }

  wasBlockEdited(blockName) {
    return this.state.getIn(["meta", "editedBlocks", blockName]);
  }

  wasEdited() {
    // marker state was edited if at least one block was edited mode
    return this.state.getIn(["meta", "editedBlocks"]).includes(true);
  }

  wasDeleted() {
    return this.state.getIn(["meta", "wasDeleted"]);
  }
}

export default alt.createStore(MarkerStateStore, "MarkerStateStore");
