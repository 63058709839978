import React, { Component } from "react";
import { bool, func, instanceOf, string } from "prop-types";
import Immutable from "immutable";
import debounce from "lodash.debounce";
import OutcomeSearchActions from "../actions/outcome_search_actions";
import OutcomeSearchStore from "../stores/outcome_search_store";
import connectStores from "./enhancers/connect_stores";

const storeConnector = {
  OutcomeSearchStore(Store, props) {
    return {
      outcomeSearchResults: Store.getSearchResults(props.searchId),
    };
  },
};

export class OutcomeNameSearchComponent extends Component {
  static doSearch = debounce(
    (searchId, searchText) => OutcomeSearchActions.search({ searchId, searchText }),
    200
  );

  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = { name: props.inputDefaultValue || "" };
    this.handleSuggestionKeyDown = this.handleSuggestionKeyDown.bind(this);
    this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNameKeyDown = this.handleNameKeyDown.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.inputRef = this.inputRef.bind(this);
    this.suggestionsContainerRef = this.suggestionsContainerRef.bind(this);
    this._resetSearch = this._resetSearch.bind(this);
  }

  componentWillUnmount() {
    // debounce to avoid dispatch in the middle of dispatch
    debounce(this._resetSearch);
  }

  _resetSearch() {
    OutcomeSearchActions.clearSearch(this.props.searchId);
  }

  inputRef(el) {
    this.input = el;
    if (typeof this.props.inputRef === "function") {
      this.props.inputRef(el);
    }
  }

  suggestionsContainerRef(el) {
    this.suggestionsContainer = el;
  }

  selectSuggestion(name, id) {
    const { onSuggestionSelect } = this.props;
    // update internal state
    this.setState({ name });
    // notify consumer if interested
    if (typeof onSuggestionSelect === "function") {
      onSuggestionSelect({ name, id });
    }
    // clear search results since selected outcome === completed search
    this._resetSearch();
    // set focus on input
    this.input.focus();
  }

  handleSuggestionSelect(evt) {
    const id = evt.target.getAttribute("data-id");
    const name = evt.target.innerText;

    this.selectSuggestion(name, id);
  }

  handleSuggestionKeyDown(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const targetEl = evt.target;

    switch (evt.keyCode) {
      // enter
      case 13:
        return this.handleSuggestionSelect(evt);
      // escape
      case 27:
        return this._resetSearch();
      // arrow up
      case 38:
        // focus previous suggestion on the list. If this is the first suggestion - focus input
        return targetEl === targetEl.parentNode.firstElementChild
          ? this.input.focus()
          : targetEl.previousElementSibling.focus();
      // arrow down
      case 40:
        // focus next suggestion on the list. If this is the last suggestion - focus input
        return targetEl === targetEl.parentNode.lastElementChild
          ? this.input.focus()
          : targetEl.nextElementSibling.focus();
      default:
        return undefined;
    }
  }

  handleNameChange(evt) {
    const name = evt.target.value;
    const nameTrimmed = name.trim();
    const { searchId } = this.props;
    // update internal state
    this.setState({ name });
    // notify consumer if interested
    if (typeof this.props.onNameChange === "function") {
      this.props.onNameChange(name);
    }
    // do search
    return nameTrimmed.length
      ? OutcomeNameSearchComponent.doSearch(searchId, nameTrimmed)
      : this._resetSearch();
  }

  handleNameKeyDown(evt) {
    evt.stopPropagation();

    const { outcomeSearchResults } = this.props;
    const { keyCode } = evt;

    // "enter" key clicked
    if (keyCode === 13 && typeof this.props.onEnterDown === "function") {
      this.props.onEnterDown(evt.target.value.trim());
    }
    // "escape" key clicked
    if (keyCode === 27) {
      this._resetSearch();
    }

    // navigation with arrows
    if (outcomeSearchResults && !outcomeSearchResults.isEmpty()) {
      if (keyCode === 38) {
        evt.preventDefault();
        // focus the last suggestion
        this.suggestionsContainer.lastElementChild.focus();
      }
      if (keyCode === 40) {
        evt.preventDefault();
        // focus the first suggestion
        this.suggestionsContainer.firstElementChild.focus();
      }
    }
  }

  handleBlur(evt) {
    // ignore clicking on suggestion
    if (this.suggestionsContainer && this.suggestionsContainer.contains(evt.relatedTarget)) return;
    OutcomeSearchActions.clearSearch(this.props.searchId);
  }

  render() {
    const { outcomeSearchResults, inputClassName, inputPlaceholder, inputId } = this.props;
    const { name } = this.state;

    return (
      <div className="outcome-name-search-container">
        <input
          autoFocus={this.props.autoFocus}
          className={inputClassName}
          id={inputId}
          name="outcome-name"
          onChange={this.handleNameChange}
          onKeyDown={this.handleNameKeyDown}
          onBlur={this.handleBlur}
          placeholder={inputPlaceholder || this.i18n("start_typing_to_see_suggestions")}
          ref={this.inputRef}
          value={name}
        />
        {name && outcomeSearchResults && !outcomeSearchResults.isEmpty() ? (
          <div className="ms-container search-results" ref={this.suggestionsContainerRef}>
            {outcomeSearchResults
              .map((result) => {
                const resultId = result.get("@id");
                const resultName = result.get("name");
                return (
                  <div
                    className="result"
                    dangerouslySetInnerHTML={{ __html: resultName }}
                    data-id={resultId}
                    key={resultId}
                    onClick={this.handleSuggestionSelect}
                    onKeyDown={this.handleSuggestionKeyDown}
                    tabIndex={0}
                  />
                );
              })
              .toList()}
          </div>
        ) : null}
      </div>
    );
  }
}

OutcomeNameSearchComponent.contextTypes = { i18n: func };

OutcomeNameSearchComponent.propTypes = {
  autoFocus: bool,
  outcomeSearchResults: instanceOf(Immutable.List),
  onSuggestionSelect: func,
  onNameChange: func,
  onEnterDown: func,
  searchId: string.isRequired,
  inputClassName: string,
  inputPlaceholder: string,
  inputId: string,
  inputRef: func,
  inputDefaultValue: string,
};

OutcomeNameSearchComponent.defaultProps = {
  autoFocus: true,
  outcomeSearchResults: null,
  onSuggestionSelect: null,
  onNameChange: null,
  onEnterDown: null,
  inputClassName: "",
  inputId: null,
  inputPlaceholder: null,
  inputRef: null,
  inputDefaultValue: "",
};

export default connectStores(OutcomeNameSearchComponent, OutcomeSearchStore, storeConnector);
