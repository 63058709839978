import { API_ENDPOINT } from '../config';
import { handleApiResponse } from './';

const OUTCOMES_URI = `${API_ENDPOINT}/outcomes`;

const api = {
  search(query) {
    return fetch(`${OUTCOMES_URI}/_search`, {
      method: 'POST',
      body: query,
    }).then(handleApiResponse);
  },

  save(outcomeData) {
    const outcomeId = outcomeData['@id'];

    return fetch(`${OUTCOMES_URI}/${outcomeId}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(outcomeData),
    }).then(handleApiResponse);
  },

  delete(outcomeId) {
    return fetch(`${OUTCOMES_URI}/${outcomeId}`, {
      method: 'DELETE',
      credentials: 'include',
    }).then(handleApiResponse);
  },

  getStats(outcomeId) {
    return fetch(`${OUTCOMES_URI}/${outcomeId}/_stats`, {
      credentials: 'include',
    }).then(handleApiResponse);
  },
};

export default api;
