import React, { Component } from "react";
import debounce from "lodash.debounce";
import Immutable from "immutable";
import { string, func, instanceOf } from "prop-types";
import OutcomeActions from "../actions/outcome_actions";
import OutcomeStore from "../stores/outcome_store";
import ModalDialog from "./common/modal_dialog";
import NewOutcomeDialog from "./outcomes/new_outcome_dialog";
import OutcomeNameSearch from "./outcome_name_search";
import connectStores from "./enhancers/connect_stores";

const storeConnectors = {
  OutcomeStore(Store) {
    return {
      outcomeSearchResults: Store.getSearchResults(),
      savingOutcomeId: Store.getSavingOutcomeId(),
      lastSavedOutcomeId: Store.getLastSavedOutcomeId(),
    };
  },
};

export class OutcomeChooseDialogComponent extends Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = {
      name: "",
      id: null,
      wasOutcomeChosen: false,
      createFromText: null,
    };
    this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleAddToList = this.handleAddToList.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.showNewOutcomeDialog = this.showNewOutcomeDialog.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { savingOutcomeId } = prevProps;
    const { onSubmit, lastSavedOutcomeId } = this.props;
    // if new outcome was successfully saved
    return savingOutcomeId && savingOutcomeId === lastSavedOutcomeId
      ? // debounce here to prevent "dispatch in the middle of dispatch" problem since onSubmit
        // triggers marker-state update action
        debounce(() => onSubmit({ name: this.state.name, id: lastSavedOutcomeId }))()
      : null;
  }

  handleSuggestionSelect({ name, id }) {
    this.setState({ name, id, wasOutcomeChosen: true });
  }

  handleNameChange(name) {
    this.setState({ name, wasOutcomeChosen: false });
  }

  handleAddToList() {
    const { name, id } = this.state;
    this.props.onSubmit({ name, id });
  }

  handleAddNew(outcomeData) {
    OutcomeActions.saveOutcome(outcomeData);
    this.setState({ name: outcomeData.name });
  }

  showNewOutcomeDialog() {
    this.setState({ createFromText: this.state.name });
  }

  render() {
    const { onClose, outcomeSearchResults, savingOutcomeId } = this.props;
    const { name, wasOutcomeChosen, createFromText } = this.state;
    const nothingFound = outcomeSearchResults && outcomeSearchResults.isEmpty() && name.length > 0;

    return createFromText ? (
      <NewOutcomeDialog
        initialText={createFromText}
        onAddNew={this.handleAddNew}
        onClose={onClose}
        submitDisabled={savingOutcomeId != null}
      />
    ) : (
      <ModalDialog containerClass="outcome-choose-dialog" onClose={onClose}>
        <div className="title-text">{this.i18n("choose_outcome_title")}</div>
        <div className="search-container">
          <OutcomeNameSearch
            inputClassName={nothingFound ? "danger" : null}
            onNameChange={this.handleNameChange}
            onSuggestionSelect={this.handleSuggestionSelect}
            searchId="outcomeChooseDialog"
          />
          {nothingFound ? (
            <div className="no-outcome-text">{this.i18n("no_outcome_text")}</div>
          ) : null}
        </div>
        <div className="buttons">
          {nothingFound ? (
            <button className="btn btn-danger create-outcome" onClick={this.showNewOutcomeDialog}>
              {this.i18n("actions.create_outcome")}
            </button>
          ) : null}
          <button
            className="btn btn-info choose"
            disabled={!wasOutcomeChosen}
            onClick={this.handleAddToList}
          >
            {this.i18n("actions.choose")}
          </button>
        </div>
      </ModalDialog>
    );
  }
}

OutcomeChooseDialogComponent.contextTypes = { i18n: func };

OutcomeChooseDialogComponent.propTypes = {
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  outcomeSearchResults: instanceOf(Immutable.List),
  savingOutcomeId: string,
  lastSavedOutcomeId: string,
};

OutcomeChooseDialogComponent.defaultProps = {
  outcomeSearchResults: null,
  savingOutcomeId: null,
  lastSavedOutcomeId: null,
};

export default connectStores(OutcomeChooseDialogComponent, OutcomeStore, storeConnectors);
