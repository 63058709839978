import React from "react";
import classNames from "classnames";
import { string, func, element, arrayOf } from "prop-types";
import TabsPane from "./tabs_pane";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.getTabsList = this.getTabsList.bind(this);
  }

  getTabsList() {
    return React.Children.map(this.props.children, (tab) => tab.props);
  }

  render() {
    const containerClass = classNames("tabs-container", this.props.className);
    const { activeTab, changeTab, children } = this.props;

    return (
      <div className={containerClass}>
        <TabsPane tabs={this.getTabsList()} activeTab={activeTab} onTabClick={changeTab} />
        {React.Children.toArray(children).filter((tab) => tab.props.tabName === activeTab)}
      </div>
    );
  }
}

Tabs.propTypes = {
  className: string,
  activeTab: string.isRequired,
  changeTab: func.isRequired,
  children: arrayOf(element).isRequired,
};

Tabs.defaultProps = {
  className: "",
};

export default Tabs;
