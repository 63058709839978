import React, { Component } from "react";
import Immutable from "immutable";
import { bool, func, instanceOf } from "prop-types";
import OutcomeActions from "../../actions/outcome_actions";
import OutcomeQuestionTypeSelection from "./outcome_question_type_selection";
import { escapeSearchHighlight } from "../../utils";

class OutcomeEdit extends Component {
  static handleQuestionTypeChange = (evt) =>
    OutcomeActions.changeOutcomeQuestionType(evt.target.value);

  static handleOutcomeNameChange = (evt) => OutcomeActions.updateOutcomeName(evt.target.value);

  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    const { outcome, isSaving } = this.props;
    const outcomeName = escapeSearchHighlight(outcome.get("name"));
    const questionType = this.props.outcome.get("type", "management");

    return (
      <div className="outcome-name editing">
        <div className="edit-container">
          <input
            defaultValue={outcomeName}
            onChange={OutcomeEdit.handleOutcomeNameChange}
            type="text"
          />
          <OutcomeQuestionTypeSelection
            onTypeSelect={OutcomeEdit.handleQuestionTypeChange}
            selectedType={questionType}
          />
        </div>
        <span className="controls">
          <button
            className="save icon"
            disabled={isSaving}
            onClick={OutcomeActions.saveEditingOutcome}
          />
        </span>
      </div>
    );
  }
}

OutcomeEdit.contextTypes = { i18n: func };

OutcomeEdit.propTypes = {
  outcome: instanceOf(Immutable.Map).isRequired,
  isSaving: bool.isRequired,
};

export default OutcomeEdit;
