import Immutable from 'immutable';
import alt from '../alt';
import DraftsActions from '../actions/drafts_actions';
import immutableStoreConfig from './configs/immutable_store';
import { reportError } from '../utils';

class DraftsStore {
  static config = immutableStoreConfig;

  constructor() {
    const initialState = {
      meta: {
        fetching: false,
        publishingIds: [],
      },
      markerStates: [],
      totalCount: 0,
    };
    this.state = Immutable.fromJS(initialState);
    this.bindActions(DraftsActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getMarkerStates: this.getMarkerStates,
      getTotalCount: this.getTotalCount,
      getPublishingIds: this.getPublishingIds,
    });
  }

  onFetch() {
    return this.setState(this.state.setIn(['meta', 'fetching'], true));
  }

  onFetchSuccess({ markerStates, totalCount }) {
    return this.setState(this.state.withMutations((state) => {
      state.setIn(['meta', 'fetching'], false);
      state.set('markerStates', Immutable.fromJS(markerStates));
      state.set('totalCount', totalCount);
    }));
  }

  onFetchError(error) {
    this.setState(this.state.setIn(['meta', 'fetching'], false));
    if (error.status !== 401) return reportError(error);
  }

  onPublish(draftId) {
    return this.setState(this.state.updateIn(['meta', 'publishingIds'], publishingIds =>
      publishingIds.push(draftId),
    ));
  }

  onPublishSuccess(draftId) {
    return this.setState(this.state.withMutations((state) => {
      // remove this draft from being published list
      state.updateIn(['meta', 'publishingIds'], publishingIds =>
        publishingIds.filterNot(id => id === draftId),
      );
      // remove this draft from list of draft marker-states
      state.update('markerStates', markerStates =>
        markerStates.filterNot(ms => ms.get('@id') === draftId),
      );
      // decrement the count
      state.update('totalCount', count => count - 1);
    }));
  }

  onPublishError({ draftId, error }) {
    this.setState(this.state.updateIn(['meta', 'publishingIds'], publishingIds =>
      publishingIds.filterNot(id => id === draftId),
    ));
    return reportError(error);
  }

  isFetching() {
    return this.state.getIn(['meta', 'fetching']);
  }

  getMarkerStates() {
    return this.state.get('markerStates');
  }

  getTotalCount() {
    return this.state.get('totalCount');
  }

  getPublishingIds() {
    return this.state.getIn(['meta', 'publishingIds']);
  }
}

export default alt.createStore(DraftsStore, 'DraftsStore');
