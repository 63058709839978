import alt from '../alt';
import { msApi, draftsApi } from '../utils';
import AppActions from './app_actions';

class MarkerStateActions {
  constructor() {
    this.generateActions(
      'fetchSuccess',
      'fetchError',
      'changeTab',
      'editBlock',
      'cancelBlockEdition',
      'applyBlockEdition',
      'updateRatingDescription',
      'updateMarkerItem',
      'updateOutcome',
      'cancelEdition',
      'saveChangesSuccess',
      'saveChangesFailure',
      'deleteSuccess',
    );
  }

  saveChanges(isDraft = false) {
    return (dispatch) => {
      dispatch();
      const api = isDraft ? draftsApi : msApi;
      const msData = alt.stores.MarkerStateStore.getMarkerState().toJS();

      return api.saveOrUpdate(msData)
        .then(this.saveChangesSuccess)
        .catch(this.saveChangesFailure);
    };
  }

  fetch(markerId, isDraft = false) {
    const api = isDraft ? draftsApi : msApi;
    return (dispatch) => {
      dispatch();
      return api.get(markerId)
        .then(this.fetchSuccess)
        .catch((err) => {
          this.fetchError(err);
          AppActions.apiError(err); // show nice error dialog at top of screen
        });
    };
  }

  deleteMarkerState(markerId, isDraft = false) {
    const api = isDraft ? draftsApi : msApi;
    return (dispatch) => {
      dispatch();
      return api.delete(markerId)
        .then(this.deleteSuccess)
        .catch(err => AppActions.apiError(err));
    };
  }
}

export default alt.createActions(MarkerStateActions);
