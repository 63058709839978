import Immutable from 'immutable';
import alt from '../alt';
import immutableStoreConfig from './configs/immutable_store';
import AppActions from '../actions/app_actions';
import { reportError } from '../utils';

class AppStore {
  static config = immutableStoreConfig;

  constructor() {
    const initialState = {
      appErrors: [],
      loggedIn: false,
      authChecked: false,
    };
    this.state = Immutable.fromJS(initialState);
    this.exportPublicMethods({
      getAppErrors: this.getAppErrors,
      isLoggedIn: this.isLoggedIn,
      authChecked: this.authChecked,
      hasEditRoleGranted: this.hasEditRoleGranted,
    });
    this.bindActions(AppActions);
  }

  onFeedbackError(err) {
    let errorText = err.toString();
    if ('errors' in err) {
      errorText = Object.entries(err.errors).reduce((acc, pair) => {
        const [fieldName, errorsList] = pair;
        const fieldErrors = `"${fieldName}": ${errorsList.join(', ')}`;
        return `${acc} ${fieldErrors};`;
      }, '');
    }
    reportError(errorText);
    return this.setState(this.state.update('appErrors', errors =>
      (errors.contains(errorText) ? errors : errors.push(errorText)),
    ));
  }

  onApiError(err) {
    const { statusText } = err;
    return this.setState(this.state.update('appErrors', errors =>
      (errors.contains(statusText) ? errors : errors.push(statusText)),
    ));
  }

  onDismissAppError(errorText) {
    return this.setState(this.state.update('appErrors', errors =>
      errors.filterNot(error => error === errorText),
    ));
  }

  onAuthSuccess() {
    return this.setState(this.state.withMutations((state) => {
      state.set('authChecked', true);
      return state.set('loggedIn', true);
    }));
  }

  onAuthError() {
    return this.setState(this.state.withMutations((state) => {
      state.set('authChecked', true);
      return state.set('loggedIn', false);
    }));
  }

  getAppErrors() {
    return this.state.get('appErrors');
  }

  isLoggedIn() {
    return this.state.get('loggedIn');
  }

  authChecked() {
    return this.state.get('authChecked');
  }

  hasEditRoleGranted() {
    // TODO: This is as simple as possible - if user is logged in then has edit role
    // Once this is implemented on backend we should update this method
    return this.state.get('loggedIn');
  }
}

export default alt.createStore(AppStore, 'AppStore');
