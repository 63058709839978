import "whatwg-fetch";
import "core-js";
import Raven from "raven-js";
import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import Cache from "i18next-localstorage-cache";
import React from "react";
import { render } from "react-dom";
import App from "./components/app";

document.addEventListener("DOMContentLoaded", () => {
  if (process.env.NODE_ENV === "production") {
    const sentryURL = "https://685641d4bb634b4e8c2322204a0ca653@o1423188.ingest.sentry.io/4504513808236544";
    Raven.config(sentryURL, {
      environment: window.location.hostname === "ms.test.evidenceprime.com"
                  ? "test"
                  : "production",
      // eslint-ignore noparam-reassign
      dataCallback(data) {
        Object.assign(data.extra != null ? data.extra : (data.extra = {}), {
          location_hash: window.location.hash,
        });
        return data;
      },
    }).install();
  }

  const lng = "en"; // to be replaced with dynamic lng var once more locales are introduced
  i18next
    .use(XHR)
    .use(Cache)
    .init(
      {
        lng,
        fallbackLng: "en",
        backend: {
          loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        ns: ["translation"],
      },
      (err, t) => {
        render(<App i18n={t} />, document.getElementById("app"));
      }
    );
});
