import React from "react";
import { func, string } from "prop-types";
import addMSSearch from "./enhancers/add_ms_search";
import { parseQueryString } from "../utils";
import OutcomeNameSearch from "./outcome_name_search";

class HeaderSearchForm extends React.Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    const { searchQuery, outcomeRef, searchContextRef, handleSearch } = this.props;
    const { outcome, context } = parseQueryString(searchQuery);

    return (
      <div className="header-search-form">
        <OutcomeNameSearch
          autoFocus={false}
          inputDefaultValue={outcome}
          inputPlaceholder={this.i18n("search.outcome_label")}
          inputRef={outcomeRef}
          onEnterDown={handleSearch}
          searchId="headerSearch"
        />
        <input
          defaultValue={context}
          name="context"
          onKeyPress={this.handleKeyPress}
          placeholder={this.i18n("context_label")}
          ref={searchContextRef}
          type="text"
        />
        <button className="btn" onClick={handleSearch} />
      </div>
    );
  }
}

HeaderSearchForm.contextTypes = { i18n: func };

HeaderSearchForm.propTypes = {
  handleSearch: func.isRequired,
  outcomeRef: func.isRequired,
  searchContextRef: func.isRequired,
  searchQuery: string.isRequired,
};

export default addMSSearch(HeaderSearchForm);
