import React from "react";
import { Link, withRouter } from "react-router-dom";
import { func, object } from "prop-types";
import classNames from "classnames";
import HeaderSearchForm from "./header_search_form";
import msLogo from "../assets/images/ms_logo.svg";
import gradeLogo from "../assets/images/grade.svg";

class Header extends React.Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    const { location } = this.props;
    const isInnerRoute = !(location.pathname === "/");
    const headerClass = classNames({ wider: isInnerRoute });

    return (
      <header className={headerClass}>
        <div className="header-container">
          <Link to="/">
            <img alt="" className="ms-logo" src={msLogo} />
          </Link>
        </div>
        {isInnerRoute ? <HeaderSearchForm searchQuery={location.search} /> : null}
        <div className="header-container">
          <img alt="" className="grade-logo" src={gradeLogo} />
        </div>
      </header>
    );
  }
}

Header.contextTypes = { i18n: func };

Header.propTypes = {
  // ReactRouter props
  location: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(Header);
