import React, { Component } from "react";
import { func } from "prop-types";
import ModalDialog from "./common/modal_dialog";
import AppActions from "../actions/app_actions";
import { emailValidityPattern } from "../utils";

class FeedbackDialog extends Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = { name: "", email: "", text: "" };
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
  }

  handleInput(evt) {
    const { name, value } = evt.target;
    return this.setState({ [name]: value });
  }

  handleSubmit() {
    const { name, email, text } = this.state;
    AppActions.sendFeedback({
      name: name.trim(),
      email: email.trim(),
      text: text.trim(),
    });
    return this.props.handleClose();
  }

  validateInputs() {
    const { name, email, text } = this.state;
    const userNameValid = name.trim().length > 0;
    const emailValid = emailValidityPattern.test(email.trim());
    const feedbackTextValid = text.trim().length > 0;
    return userNameValid && emailValid && feedbackTextValid;
  }

  render() {
    const { name, email, text } = this.state;
    const allInputsValid = this.validateInputs();

    return (
      <ModalDialog containerClass="feedback-dialog" onClose={this.props.handleClose}>
        <div className="title">{this.i18n("feedback_dialog_title")}</div>
        <div className="inputs-container">
          <input
            autoFocus
            name="name"
            onChange={this.handleInput}
            placeholder={this.i18n("name")}
            required
            type="text"
            value={name}
          />
          <input
            name="email"
            onChange={this.handleInput}
            pattern={emailValidityPattern.toString().slice(1, -1)}
            placeholder={this.i18n("email")}
            required
            type="email"
            value={email}
          />
        </div>
        <div className="text-container__with-submit">
          <textarea
            name="text"
            onChange={this.handleInput}
            placeholder={this.i18n("your_feedback_here")}
            required
            value={text}
          />
          <button className="btn btn-info" disabled={!allInputsValid} onClick={this.handleSubmit}>
            {this.i18n("actions.send")}
          </button>
        </div>
      </ModalDialog>
    );
  }
}

FeedbackDialog.contextTypes = { i18n: func };

FeedbackDialog.propTypes = {
  handleClose: func.isRequired,
};

export default FeedbackDialog;
