import React, { Component } from "react";
import classNames from "classnames";
import { func, string } from "prop-types";
import ModalDialog from "./modal_dialog";

class NotificationDialog extends Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    const { onClose, notificationText, className } = this.props;
    const dialogClass = classNames("notification-dialog", className);
    return (
      <ModalDialog containerClass={dialogClass} onClose={onClose}>
        <div className="title">{notificationText}</div>
        <div className="confirmation-buttons">
          <button className="btn btn-action" onClick={onClose}>
            {this.i18n("actions.understood")}
          </button>
        </div>
      </ModalDialog>
    );
  }
}

NotificationDialog.contextTypes = { i18n: func };

NotificationDialog.propTypes = {
  notificationText: string.isRequired,
  onClose: func.isRequired,
  className: string,
};

NotificationDialog.defaultProps = {
  className: "",
};

export default NotificationDialog;
