import React from "react";
import SearchForm from "./search_form";
import GdtBox from "./gdt_box";
import DescriptionBox from "./description_box";

// eslint-disable-next-line react/prefer-stateless-function
class Home extends React.Component {
  render() {
    return (
      <div>
        <div className="home-container">
          <SearchForm />
          <GdtBox />
        </div>
        <div className="home-container">
          <DescriptionBox />
        </div>
      </div>
    );
  }
}

export default Home;
