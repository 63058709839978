import React, { Component } from "react";
import classNames from "classnames";
import { func, string, array, object, oneOfType } from "prop-types";
import ModalDialog from "./modal_dialog";

class ConfirmationDialog extends Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    const { onCancel, onConfirm, confirmationText, className } = this.props;
    const dialogClass = classNames("confirmation-dialog", className);
    return (
      <ModalDialog containerClass={dialogClass} onClose={onCancel}>
        <div className="title">{confirmationText}</div>
        {this.props.children}
        <div className="confirmation-buttons">
          <button className="btn btn-cancel" onClick={onCancel}>
            {this.i18n("actions.cancel")}
          </button>
          <button className="btn btn-action" onClick={onConfirm}>
            {this.i18n("actions.confirm")}
          </button>
        </div>
      </ModalDialog>
    );
  }
}

ConfirmationDialog.contextTypes = { i18n: func };

ConfirmationDialog.propTypes = {
  confirmationText: string.isRequired,
  children: oneOfType([array, object]),
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  className: string,
};

ConfirmationDialog.defaultProps = {
  className: "",
  children: null,
};

export default ConfirmationDialog;
