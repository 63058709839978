import React from "react";
import PropTypes from "prop-types";

const OutcomeQuestionTypeSelection = (props, { i18n }) => {
  const typeSelectHandler = (evt) => props.onTypeSelect(evt.target.value);

  return (
    <div className="outcome-question-type-selection">
      {["management", "diagnostic"].map((type) => (
        <label className="outcome-type" htmlFor={`type-${type}`} key={type}>
          <input
            defaultChecked={type === props.selectedType}
            id={`type-${type}`}
            name="outcome-type"
            onChange={typeSelectHandler}
            type="radio"
            value={type}
          />
          {i18n(`outcomes_list.${type}`)}
        </label>
      ))}
    </div>
  );
};

OutcomeQuestionTypeSelection.contextTypes = { i18n: PropTypes.func };

OutcomeQuestionTypeSelection.propTypes = {
  selectedType: PropTypes.string.isRequired,
};

export default OutcomeQuestionTypeSelection;
