import { API_ENDPOINT, paginationLimit } from '../config';
import { handleApiResponse } from './';

const DRAFTS_URI = `${API_ENDPOINT}/markerstates/_draft`;

const api = {
  fetch(params) {
    const start = params.start || 0;
    const limit = params.limit || paginationLimit;
    return fetch(`${DRAFTS_URI}?start=${start}&limit=${limit}`, {
      credentials: 'include',
    }).then(handleApiResponse);
  },

  get(markerId) {
    return fetch(`${DRAFTS_URI}/${markerId}`, { credentials: 'include' }).then(handleApiResponse);
  },

  saveOrUpdate(draftDoc) {
    const draftId = draftDoc['@id'];
    return fetch(`${DRAFTS_URI}/${draftId}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(draftDoc),
    }).then(handleApiResponse);
  },

  delete(draftId) {
    return fetch(`${DRAFTS_URI}/${draftId}`, {
      method: 'DELETE',
      credentials: 'include',
    }).then(handleApiResponse);
  },

  publish(draftId) {
    return fetch(`${DRAFTS_URI}/${draftId}/_publish`, {
      method: 'POST',
      credentials: 'include',
    }).then(handleApiResponse);
  },
};

export default api;
