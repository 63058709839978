import React from "react";
import { Route, Redirect } from "react-router-dom";
import { bool, func } from "prop-types";
import AppStore from "../stores/app_store";
import connectStores from "./enhancers/connect_stores";
import Spinner from "./spinner";

const storeConnector = {
  AppStore(Store) {
    return {
      authChecked: Store.authChecked(),
      isLoggedIn: Store.isLoggedIn(),
    };
  },
};

const ProtectedRoute = ({ component: Component, authChecked, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!authChecked) return <Spinner />;

      return isLoggedIn ? <Component {...props} /> : <Redirect to="/" />;
    }}
  />
);

ProtectedRoute.propTypes = {
  component: func.isRequired,
  authChecked: bool.isRequired,
  isLoggedIn: bool.isRequired,
};

export default connectStores(ProtectedRoute, AppStore, storeConnector);
