import React from "react";
import { bool, func } from "prop-types";
import { withRouter } from "react-router-dom";

class MarkerStateEditButtons extends React.Component {
  static contextTypes = {
    i18n: func,
  };

  static propTypes = {
    // ReactRouter props
    isDraft: bool.isRequired,
    isNewDraft: bool.isRequired,
    wasEdited: bool.isRequired,
    onCancel: func.isRequired,
    onSave: func.isRequired,
    onDelete: func.isRequired,
  };

  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    const { onCancel, onDelete, onSave, wasEdited, isDraft, isNewDraft } = this.props;

    return (
      <div className="marker-state-bottom-buttons">
        {isNewDraft ? null : (
          <button className="btn btn-danger" onClick={onDelete}>
            {this.i18n("actions.delete")}
          </button>
        )}
        {wasEdited ? (
          <div className="cancel-save-buttons">
            <button className="btn btn-cancel" onClick={onCancel}>
              {this.i18n("actions.cancel")}
            </button>
            <button className="btn btn-action" onClick={onSave}>
              {this.i18n(isDraft ? "actions.save" : "actions.add_to_list")}
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(MarkerStateEditButtons);
