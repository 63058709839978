import alt from "../alt";
import { outcomesApi, getQueryString } from "../utils";

class OutcomeSearchActions {
  constructor() {
    this.generateActions("searchSuccess", "searchError", "clearSearch");
  }

  search({ searchId, searchText }) {
    const query = getQueryString("outcomeSearch", searchText);

    return (dispatch) => {
      dispatch({ searchId, searchText });
      return outcomesApi
        .search(query)
        .then(({ outcomes }) => this.searchSuccess({ searchId, outcomes }))
        .catch((error) => this.searchError({ searchId, error }));
    };
  }
}

export default alt.createActions(OutcomeSearchActions);
