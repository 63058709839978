import React from 'react';
import { string, func, bool } from 'prop-types';
import MarkerDetailsRatingDesc from './marker_details_rating_desc';
import MarkerStateEditIcons from './marker_state_edit_icons';

const MarkerDetailsTab = (props, context) => {
  const { ratingType, inEditMode } = props;
  const { i18n } = context;
  return (<div className="marker-details-tab">
    {props.editable ?
      <MarkerStateEditIcons blockName={props.ratingType} inEditMode={props.inEditMode} /> :
      null
    }
    <MarkerDetailsRatingDesc
      description={props.symptoms}
      inEditMode={inEditMode}
      label={i18n('marker_details.symptoms')}
      name="symptoms"
      ratingType={ratingType}
    />
    <MarkerDetailsRatingDesc
      description={props.timeHorizon}
      inEditMode={inEditMode}
      label={i18n('marker_details.time_horizon')}
      name="timeHorizon"
      ratingType={ratingType}
    />
    <MarkerDetailsRatingDesc
      description={props.testingAndTreatment}
      inEditMode={inEditMode}
      label={i18n('marker_details.testing_and_treatment')}
      name="testingAndTreatment"
      ratingType={ratingType}
    />
    <MarkerDetailsRatingDesc
      description={props.consequences}
      inEditMode={inEditMode}
      label={i18n('marker_details.consequences')}
      name="consequences"
      ratingType={ratingType}
    />
  </div>);
};

MarkerDetailsTab.propTypes = {
  symptoms: string.isRequired,
  timeHorizon: string.isRequired,
  testingAndTreatment: string.isRequired,
  consequences: string.isRequired,
  ratingType: string.isRequired,
  // editable - show edit buttons
  editable: bool,
  // inEditMode - the component is actually edited
  inEditMode: bool,
};

MarkerDetailsTab.defaultProps = {
  editable: false,
  inEditMode: false,
};

MarkerDetailsTab.contextTypes = { i18n: func };

export default MarkerDetailsTab;
