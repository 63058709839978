import Immutable from 'immutable';
import alt from '../alt';
import { reportError } from '../utils';
import OutcomeSearchActions from '../actions/outcome_search_actions';
import immutableStoreConfig from './configs/immutable_store';

const INIT_STATE = Immutable.fromJS({
  meta: { searching: {} },
  searchResults: {},
});

class OutcomeSearchStore {
  static config = immutableStoreConfig;

  constructor() {
    this.state = INIT_STATE;
    this.exportPublicMethods({
      isSearching: this.isSearching,
      getSearchResults: this.getSearchResults,
    });
    this.bindActions(OutcomeSearchActions);
  }

  onSearch({ searchId }) {
    return this.setState(
      this.state.setIn(['meta', 'searching', searchId], true),
    );
  }

  onSearchSuccess({ searchId, outcomes }) {
    return this.setState(this.state.withMutations((state) => {
      state.setIn(['meta', 'searching', searchId], false);
      state.setIn(['searchResults', searchId], Immutable.fromJS(outcomes));
    }));
  }

  onSearchError({ searchId, error }) {
    reportError(error);
    return this.setState(this.state.setIn(['meta', 'searching', searchId], false));
  }

  onClearSearch(searchId) {
    return this.setState(this.state.withMutations((state) => {
      state.setIn(['meta', 'searching', searchId], false);
      state.setIn(['searchResults', searchId], null);
    }));
  }

  isSearching(searchId) {
    return this.state.getIn(['meta', 'searching', searchId], false);
  }

  getSearchResults(searchId) {
    return this.state.getIn(['searchResults', searchId], null);
  }
}

export default alt.createStore(OutcomeSearchStore, 'OutcomeSearchStore');
