import React from "react";
import classNames from "classnames";
import { string, element } from "prop-types";

const Tab = ({ className, children }) => {
  const tabClass = classNames("tab-content", className);
  return <div className={tabClass}>{children}</div>;
};

Tab.propTypes = {
  children: element.isRequired,
  className: string,
};

Tab.defaultProps = {
  className: "",
};

export default Tab;
