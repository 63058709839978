import React from "react";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { number, string, bool, object } from "prop-types";
import { parseQueryString } from "../utils";
import { paginationLimit } from "../config";

const PageNumber = (props) => {
  const { idx, URL, isActive } = props;
  return (
    <span key={URL} className={classNames({ active: isActive })}>
      <Link to={URL}>{idx + 1}</Link>
    </span>
  );
};

PageNumber.propTypes = {
  idx: number.isRequired,
  URL: string.isRequired,
  isActive: bool.isRequired,
};

class MarkerStatesPagination extends React.Component {
  getPageURLs(queryParams, start, limit) {
    const { itemsCount, location } = this.props;
    const { pathname } = location;
    const queryStr = Object.keys(queryParams).reduce((acc, paramName) => {
      // ignore pagination params
      if (paramName === "start" || paramName === "limit") return acc;
      const paramVal = queryParams[paramName];
      return `${acc}${paramName}=${paramVal}&`;
    }, "");
    const URLprefix = `${pathname}?${queryStr}`;
    const noOfPages = Math.ceil(itemsCount / limit);
    const pageURLs = [];
    let pageIdx = 0;

    while (pageIdx <= noOfPages - 1) {
      pageURLs.push(`${URLprefix}start=${pageIdx * limit}&limit=${limit}`);
      pageIdx += 1;
    }
    return pageURLs;
  }

  render() {
    const { location, itemsCount } = this.props;
    const queryParams = parseQueryString(location.search);
    const limit = queryParams.limit || paginationLimit;
    if (itemsCount <= limit) return null;

    const start = queryParams.start || 0;
    const pageURLs = this.getPageURLs(queryParams, start, limit);
    const activePageIdx = Math.floor(start / limit);

    return (
      <div className="pagination-container">
        {activePageIdx > 0 ? (
          <Link to={pageURLs[activePageIdx - 1]}>
            <span className="arrow-left" />
          </Link>
        ) : null}
        {pageURLs.map((URL, idx) => (
          <PageNumber key={URL} idx={idx} URL={URL} isActive={idx === activePageIdx} />
        ))}
        {activePageIdx < pageURLs.length - 1 ? (
          <Link to={pageURLs[activePageIdx + 1]}>
            <span className="arrow-right" />
          </Link>
        ) : null}
      </div>
    );
  }
}

MarkerStatesPagination.propTypes = {
  itemsCount: number.isRequired,
  // ReactRouter props
  location: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(MarkerStatesPagination);
