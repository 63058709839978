import { handleApiResponse } from './';

const ENDPOINT_URI = process.env.NODE_ENV === 'production' ?
  'https://api.gdt.gradepro.org/feedback' :
  'https://api.gdt.test.evidenceprime.com/feedback';

const api = {
  send({ name, email, text }) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('text', text);
    formData.append('source', 'MarkerStates');
    return fetch(ENDPOINT_URI, {
      method: 'POST',
      body: formData,
    }).then(handleApiResponse);
  },
};

export default api;
