import { API_ENDPOINT } from '../config';
import { handleApiResponse, queryParamsToQueryString } from './';

const MARKERSTATES_URI = `${API_ENDPOINT}/markerstates`;

const api = {
  search(query, queryParams = {}) {
    const queryParamsString = queryParamsToQueryString(queryParams);

    return fetch(`${MARKERSTATES_URI}/_search?${queryParamsString}`, {
      method: 'POST',
      body: query,
    }).then(handleApiResponse);
  },

  get(markerId) {
    return fetch(`${MARKERSTATES_URI}/${markerId}`).then(handleApiResponse);
  },

  saveOrUpdate(markerDoc) {
    const id = markerDoc['@id'];
    return fetch(`${MARKERSTATES_URI}/${id}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(markerDoc),
    }).then(handleApiResponse);
  },

  delete(markerId) {
    return fetch(`${MARKERSTATES_URI}/${markerId}`, {
      method: 'DELETE',
      credentials: 'include',
    }).then(handleApiResponse);
  },
};

export default api;
