import React from 'react';
import { instanceOf } from 'prop-types';
import { List } from 'immutable';
import MarkerStateItem from './marker_state_item';

const MarkerStatesList = props => (
  <div className="marker-states-container">
    {props.markerStates.map(markerState =>
      <MarkerStateItem
        author={markerState.get('author')}
        background={markerState.get('background', '')}
        context={markerState.get('context')}
        dateCreated={markerState.get('dateCreated')}
        id={markerState.get('@id')}
        key={markerState.get('@id')}
        name={markerState.get('name')}
        shortBackground
      />,
    ).toList()
    }
  </div>
);

MarkerStatesList.propTypes = {
  markerStates: instanceOf(List).isRequired,
};

export default MarkerStatesList;
