import React, { Component } from "react";
import { func, instanceOf, bool, object } from "prop-types";
import Immutable from "immutable";
import debounce from "lodash.debounce";
import connectStores from "../enhancers/connect_stores";
import OutcomeStore from "../../stores/outcome_store";
import OutcomeActions from "../../actions/outcome_actions";
import OutcomesList from "./outcomes_list";
import NewOutcomeDialog from "./new_outcome_dialog";

const storeConnectors = {
  OutcomeStore(Store) {
    return {
      isSearching: Store.isSearching(),
      isSaving: Store.isSaving(),
      isDeleting: Store.isDeleting(),
      outcomes: Store.getSearchResults(),
      editingOutcome: Store.getEditingOutcome(),
      outcomesStats: Store.getOutcomesStats(),
    };
  },
};

class OutcomesComponent extends Component {
  static doSearch = debounce((searchString) => {
    if (!searchString) return;
    OutcomeActions.search(searchString);
  }, 200);

  static handleSearchChange = (evt) => {
    const searchString = evt.target.value;

    if (searchString) {
      const trimmed = searchString.trim();
      OutcomesComponent.doSearch(trimmed);
    } else {
      OutcomeActions.clearSearch();
    }
  };

  static defaultProps = {
    outcomes: null,
  };

  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = { showAddNewDialog: false };
    this.toggleAddNewDialog = this.toggleAddNewDialog.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillUnmount() {
    OutcomeActions.clearSearch();
  }

  toggleAddNewDialog() {
    this.setState({ showAddNewDialog: !this.state.showAddNewDialog });
  }

  handleAddNew(outcomeData) {
    OutcomeActions.saveOutcome(outcomeData);
    this.toggleAddNewDialog();
  }

  handleBack() {
    return this.props.history.push({
      pathname: "/drafts",
    });
  }

  render() {
    const { isSearching, isSaving, isDeleting, outcomes, editingOutcome, outcomesStats } =
      this.props;
    const { showAddNewDialog } = this.state;

    return (
      <div className="outcomes-container">
        <button className="btn btn-primary btn-back" onClick={this.handleBack}>
          {this.i18n("actions.back_to_drafts")}
        </button>
        <div className="ms-container ">
          <div className="title-text">{this.i18n("outcomes_list.outcomes_list")}</div>
          <div className="upper-container">
            <input
              autoFocus
              className="outcome-search"
              onChange={OutcomesComponent.handleSearchChange}
              placeholder={this.i18n("outcomes_list.search_outcomes")}
              type="text"
            />
            <button className="btn btn-danger" onClick={this.toggleAddNewDialog}>
              {this.i18n("outcomes_list.add_new_outcome")}
            </button>
          </div>
          {isSearching ? (
            <div>{this.i18n("search.searching")}</div>
          ) : (
            <OutcomesList
              editingOutcome={editingOutcome}
              isDeleting={isDeleting}
              isSaving={isSaving}
              outcomes={outcomes}
              outcomesStats={outcomesStats}
            />
          )}
        </div>
        <div className="add-new-dialog-container">
          {showAddNewDialog ? (
            <NewOutcomeDialog onAddNew={this.handleAddNew} onClose={this.toggleAddNewDialog} />
          ) : null}
        </div>
      </div>
    );
  }
}

OutcomesComponent.contextTypes = { i18n: func };

OutcomesComponent.propTypes = {
  isSearching: bool.isRequired,
  isSaving: bool.isRequired,
  isDeleting: bool.isRequired,
  outcomes: instanceOf(Immutable.List),
  editingOutcome: instanceOf(Immutable.Map).isRequired,
  outcomesStats: instanceOf(Immutable.Map).isRequired,
  // eslint-disable-line react/forbid-prop-types
  history: object.isRequired,
};

OutcomesComponent.defaultProps = {
  outcomes: Immutable.List(),
};

export default connectStores(OutcomesComponent, OutcomeStore, storeConnectors);
