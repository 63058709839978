import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import DayPicker from "react-day-picker";
import { string, func, bool } from "prop-types";
import parse from "date-fns/parse";
import { formatDate, formatIso8601Date } from "../utils";
import OutcomeNameEdit from "./outcome_name_edit";
import MarkerStateEditIcons from "./marker_state_edit_icons";
import MarkerStateActions from "../actions/marker_state_actions";
import EllipsizedText from "./common/ellipsized_text";

class MarkerStateItem extends PureComponent {
  constructor(props, context) {
    super(props);
    this.state = { showDatePicker: false };
    this.i18n = context.i18n;
    this.toggleDatePicker = this.toggleDatePicker.bind(this);
    this.handleDateUpdate = this.handleDateUpdate.bind(this);
  }

  getDetailsLink() {
    const id = this.props.id;
    return {
      pathname: `/marker-states/${id}`,
      state: {
        fromList: true,
      },
    };
  }

  toggleDatePicker() {
    this.setState({ showDatePicker: !this.state.showDatePicker });
  }

  updateInputValue = (evt) =>
    MarkerStateActions.updateMarkerItem({
      name: evt.target.id,
      value: evt.target.value,
    });

  handleDateUpdate(newDate) {
    MarkerStateActions.updateMarkerItem({ name: "dateCreated", value: formatIso8601Date(newDate) });
    this.toggleDatePicker();
  }

  renderDisplayMode() {
    const { name, context, author, background, dateCreated, noLink, shortBackground } = this.props;
    const link = this.getDetailsLink();

    return (
      /* eslint-disable react/no-danger */
      <div>
        {noLink ? (
          <div className="outcome-name" dangerouslySetInnerHTML={{ __html: name }} />
        ) : (
          <Link className="outcome-link item-line" to={link}>
            <div className="outcome-name" dangerouslySetInnerHTML={{ __html: name }} />
          </Link>
        )}
        <div className="item-line context">
          <span className="label">{this.i18n("context_label")}:</span>
          <span dangerouslySetInnerHTML={{ __html: context }} />
        </div>
        <div className="item-line background">
          <span className="label">{this.i18n("background_label")}:</span>
          {shortBackground ? (
            <EllipsizedText height={40} text={background} />
          ) : (
            <span>{background}</span>
          )}
        </div>
        <div className="item-line author">
          <span className="label">{this.i18n("author")}:</span>
          <span>{author}</span>
        </div>
        <div className="item-line date-created">
          <span className="label">{this.i18n("date")}:</span>
          <span>{formatDate(dateCreated)}</span>
        </div>
      </div>
      /* eslint-enable react/no-danger */
    );
  }

  renderEditMode() {
    const { name, context, author, dateCreated, background } = this.props;
    return (
      <div className="edit-form">
        <fieldset>
          <label className="item-label" htmlFor="outcome">
            {this.i18n("outcome")}:
          </label>
          <OutcomeNameEdit name={name} />
          <label className="item-label" htmlFor="context">
            {this.i18n("context_label")}:
          </label>
          <input
            className="item-input"
            id="context"
            type="text"
            value={context}
            onChange={this.updateInputValue}
          />
          <label className="item-label" htmlFor="background">
            {this.i18n("background_label")}:
          </label>
          <input
            className="item-input"
            id="background"
            type="text"
            value={background}
            onChange={this.updateInputValue}
          />
          <label className="item-label" htmlFor="author">
            {this.i18n("author")}:
          </label>
          <input
            className="item-input"
            id="author"
            type="text"
            value={author}
            onChange={this.updateInputValue}
          />
          <label className="item-label" htmlFor="dateCreated">
            {this.i18n("date")}:
          </label>
          <input
            className="item-input"
            id="dateCreated"
            type="text"
            readOnly
            onClick={this.toggleDatePicker}
            value={formatDate(dateCreated)}
          />
          {this.state.showDatePicker ? (
            <div className="DayPicker-Container">
              <DayPicker
                initialMonth={parse(dateCreated)}
                onDayClick={this.handleDateUpdate}
                selectedDays={parse(dateCreated)}
              />
            </div>
          ) : null}
        </fieldset>
      </div>
    );
  }

  render() {
    const { editable, inEditMode } = this.props;
    return (
      <div className="marker-state-item">
        {editable ? <MarkerStateEditIcons blockName="overview" inEditMode={inEditMode} /> : null}
        {inEditMode ? this.renderEditMode() : this.renderDisplayMode()}
      </div>
    );
  }
}

MarkerStateItem.contextTypes = { i18n: func };

MarkerStateItem.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  context: string.isRequired,
  author: string.isRequired,
  background: string.isRequired,
  dateCreated: string.isRequired,
  // editable means that there is an edit icon
  editable: bool,
  // if editable then we can set editMode - text fields are displyed
  inEditMode: bool,
  noLink: bool,
  shortBackground: bool,
};

MarkerStateItem.defaultProps = {
  editable: false,
  inEditMode: false,
  noLink: false,
  shortBackground: false,
};

export default MarkerStateItem;
