import Immutable from 'immutable';
import alt from '../alt';
import immutableStoreConfig from './configs/immutable_store';
import OutcomeActions from '../actions/outcome_actions';
import { reportError } from '../utils';

const INIT_STATE = Immutable.fromJS({
  meta: {
    isSearching: false,
    editingOutcome: {},
    savingOutcomeId: null,
    isDeleting: false,
    lastSavedOutcomeId: null,
    stats: {},
  },
  outcomeSearchResults: null,
  markerStates: {},
});

class OutcomeStore {
  static config = immutableStoreConfig;

  constructor() {
    this.state = INIT_STATE;
    this.bindActions(OutcomeActions);
    this.exportPublicMethods({
      isSearching: this.isSearching,
      getSearchResults: this.getSearchResults,
      getOutcomeMarkerStates: this.getOutcomeMarkerStates.bind(this),
      getEditingOutcome: this.getEditingOutcome,
      isSaving: this.isSaving,
      isDeleting: this.isDeleting,
      getSavingOutcomeId: this.getSavingOutcomeId,
      getLastSavedOutcomeId: this.getLastSavedOutcomeId,
      getOutcomesStats: this.getOutcomesStats,
    });
  }

  onSearch() {
    // reset all data and set searching flag
    return this.setState(INIT_STATE.setIn(['meta', 'isSearching'], true));
  }

  onClearSearch() {
    this.setState(INIT_STATE);
  }

  onSearchSuccess({ outcomes }) {
    // search action is debounced, it may happen that by the time results arrive, the search is no
    // longer active, e.g. user emptied searching field
    if (this.state.getIn(['meta', 'isSearching'])) {
      this.setState(this.state.withMutations((state) => {
        state.set('outcomeSearchResults', Immutable.fromJS(outcomes));
        state.setIn(['meta', 'isSearching'], false);
      }));
    }
  }

  onSearchError(err) {
    this.setState(this.state.setIn(['meta', 'isSearching'], false));
    return reportError(err);
  }

  onFetchOutcomeMarkerStatesSuccess({ outcomeId, markerStates }) {
    this.setState(this.state.setIn(['markerStates', outcomeId], Immutable.fromJS(markerStates)));
  }

  onFetchOutcomeMarkerStatesError({ outcomeId }) {
    this.setState(this.state.setIn(['markerStates', outcomeId], Immutable.List()));
  }

  onEditOutcome(outcomeData) {
    return this.setState(this.state.setIn(['meta', 'editingOutcome'], outcomeData));
  }

  onChangeOutcomeQuestionType(type) {
    this.setState(this.state.setIn(['meta', 'editingOutcome', 'questionType'], type));
  }

  onUpdateOutcomeName(newName) {
    this.setState(this.state.setIn(['meta', 'editingOutcome', 'name'], newName));
  }

  onSaveOutcome(outcomeId) {
    this.setState(this.state.setIn(['meta', 'savingOutcomeId'], outcomeId));
  }

  onDeleteOutcome() {
    this.setState(this.state.setIn(['meta', 'isDeleting'], true));
  }

  onSaveOutcomeSuccess({ id }) {
    this.setState(this.state.withMutations((state) => {
      // update the results with new edited data if edited outcome was in results
      state.update('outcomeSearchResults', (results) => {
        const editingOutcomeIdx = results.findIndex(r => r.get('@id') === id);
        return editingOutcomeIdx >= 0 ?
          results.set(editingOutcomeIdx, state.getIn(['meta', 'editingOutcome'])) :
          results;
      });
      state.setIn(['meta', 'savingOutcomeId'], null);
      state.setIn(['meta', 'editingOutcome'], Immutable.Map());
      state.setIn(['meta', 'lastSavedOutcomeId'], id);
    }));
  }

  onDeleteOutcomeSuccess(outcomeId) {
    this.setState(this.state.withMutations((state) => {
      state.setIn(['meta', 'isDeleting'], false);
      state.update('outcomeSearchResults', results =>
        results.filterNot(result => result.get('@id') === outcomeId),
      );
    }));
  }

  onSaveOutcomeError(err) {
    reportError(err);
    this.setState(this.state.setIn(['meta', 'savingOutcomeId'], null));
  }

  onFetchOutcomeStatsSuccess({ outcomeId, stats }) {
    return this.setState(this.state.setIn(['meta', 'stats', outcomeId], Immutable.fromJS(stats)));
  }

  isSearching() {
    return this.state.getIn(['meta', 'isSearching']);
  }

  getSearchResults() {
    return this.state.get('outcomeSearchResults');
  }

  getOutcomeMarkerStates(outcomeId) {
    return this.state.getIn(['markerStates', outcomeId]);
  }

  getEditingOutcome() {
    return this.state.getIn(['meta', 'editingOutcome']);
  }

  getSavingOutcomeId() {
    return this.state.getIn(['meta', 'savingOutcomeId']);
  }

  getLastSavedOutcomeId() {
    return this.state.getIn(['meta', 'lastSavedOutcomeId']);
  }

  isSaving() {
    return this.state.getIn(['meta', 'savingOutcomeId']) != null;
  }

  isDeleting() {
    return this.state.getIn(['meta', 'isDeleting']);
  }

  getOutcomesStats() {
    return this.state.getIn(['meta', 'stats']);
  }
}

export default alt.createStore(OutcomeStore, 'OutcomeStore');
