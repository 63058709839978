import React from "react";
import classNames from "classnames";
import { string, bool, func, arrayOf, shape } from "prop-types";

class TabsPane extends React.Component {
  constructor(props) {
    super(props);
    this.renderTab = this.renderTab.bind(this);
  }

  renderTab(tabProps, idx) {
    const { tabName, tabLabel, tabStyle, disabled } = tabProps;
    const tabClass = classNames("tab", {
      "tab--disabled": disabled,
      "tab--selected": this.props.activeTab === tabName,
    });

    return (
      <div
        key={idx}
        className={tabClass}
        style={tabStyle}
        onClick={() => {
          if (!disabled) this.props.onTabClick(tabName);
        }}
      >
        {tabLabel}
      </div>
    );
  }

  render() {
    return <div className="tabspane">{this.props.tabs.map(this.renderTab)}</div>;
  }
}

TabsPane.propTypes = {
  tabs: arrayOf(
    shape({
      tabName: string,
      tabLabel: string,
      tabStyle: string,
      disabled: bool,
    })
  ).isRequired,
  activeTab: string.isRequired,
  onTabClick: func.isRequired,
};

TabsPane.defaultProps = {
  className: "",
};

export default TabsPane;
