import React from 'react';
import { string, shape, func } from 'prop-types';
import iconWrapper from './icon_wrapper';

const IconButton = props => (
  <button style={props.style} className={props.iconClass} onClick={props.onClick} />
);

IconButton.propTypes = {
  onClick: func.isRequired,
  // these props are provided by the wrapper
  iconClass: string.isRequired,
  style: shape({
    width: string,
    height: string,
  }).isRequired,
};

export default iconWrapper(IconButton);
