import React from "react";
import { object } from "prop-types";
import { withRouter } from "react-router-dom";

const addMSSearch = (WrappedComponent) => {
  class MSSearchWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.outcomeRef = this.outcomeRef.bind(this);
      this.searchContextRef = this.searchContextRef.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
    }

    outcomeRef(el) {
      this.outcomeName = el;
    }

    searchContextRef(el) {
      this.searchContext = el;
    }

    handleSearch() {
      const outcomeName = this.outcomeName && this.outcomeName.value.trim();
      const searchContext = this.searchContext && this.searchContext.value.trim();
      return outcomeName || searchContext
        ? this.props.history.push({
            pathname: "/search",
            search: `?outcome=${outcomeName}&context=${searchContext}`,
          })
        : undefined;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleSearch={this.handleSearch}
          outcomeRef={this.outcomeRef}
          searchContextRef={this.searchContextRef}
        />
      );
    }
  }

  MSSearchWrapper.propTypes = {
    // ReactRouter props
    history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  return withRouter(MSSearchWrapper);
};

export default addMSSearch;
