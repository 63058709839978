import React, { Component } from "react";
import { func, string } from "prop-types";
import MarkerStateActions from "../actions/marker_state_actions";
import OutcomeChooseDialog from "./outcome_choose_dialog";

class OutcomeNameEdit extends Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = { showChooseOutcomeDialog: false };
    this.toggleChooseOutcome = this.toggleChooseOutcome.bind(this);
    this.handleOutcomeSelection = this.handleOutcomeSelection.bind(this);
  }

  toggleChooseOutcome() {
    this.setState({ showChooseOutcomeDialog: !this.state.showChooseOutcomeDialog });
  }

  handleOutcomeSelection(outcomeData) {
    MarkerStateActions.updateOutcome(outcomeData);
    this.toggleChooseOutcome();
  }

  render() {
    const { name } = this.props;
    const { showChooseOutcomeDialog } = this.state;

    return (
      <div className="outcome-name-container">
        <input
          id="outcome"
          onClick={this.toggleChooseOutcome}
          placeholder={this.i18n("outcome_name_placeholder")}
          readOnly
          type="text"
          value={name}
        />
        <div className="choose-outcome-dialog-container">
          {showChooseOutcomeDialog ? (
            <OutcomeChooseDialog
              onClose={this.toggleChooseOutcome}
              onSubmit={this.handleOutcomeSelection}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

OutcomeNameEdit.contextTypes = { i18n: func };

OutcomeNameEdit.propTypes = {
  name: string,
};

OutcomeNameEdit.defaultProps = {
  name: "",
  isSearching: false,
  outcomeSearchResults: null,
};

export default OutcomeNameEdit;
