import React, { Component } from "react";
import { array, func, string } from "prop-types";
import classNames from "classnames";

class ModalDialog extends Component {
  constructor(props) {
    super(props);
    this.escapeHandler = this.escapeHandler.bind(this);
  }

  escapeHandler(evt) {
    // close if escape was clicked
    return evt.keyCode === 27 ? this.props.onClose() : undefined;
  }

  render() {
    const contentBoxClass = classNames("ms-container", "dialog-content", this.props.containerClass);

    return (
      <div className="modal-dialog" onKeyDown={this.escapeHandler} tabIndex="0">
        <div className="overlay" onClick={this.props.onClose} />
        <div className={contentBoxClass}>{this.props.children}</div>
      </div>
    );
  }
}

ModalDialog.propTypes = {
  onClose: func.isRequired,
  children: array.isRequired,
  containerClass: string,
};

ModalDialog.defaultProps = {
  containerClass: "",
};

export default ModalDialog;
