import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { withCookies, Cookies } from "react-cookie";

class DescriptionBox extends React.Component {
  static contextTypes = { i18n: PropTypes.func };

  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
  };

  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = {
      expanded: props.cookies.get("show-description") !== "false",
    };

    this.toggleDescription = this.toggleDescription.bind(this);
  }

  componentDidMount() {
    this.props.cookies.set("show-description", "false");
  }

  toggleDescription() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const containerClasses = classNames("ms-container description-box", {
      expanded: this.state.expanded,
      collapsed: !this.state.expanded,
    });

    return (
      <div className={containerClasses} onClick={this.toggleDescription}>
        {this.state.expanded ? (
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.i18n("app_description") }} />
            <button className="description-read-more-btn" onClick={this.toggleDescription}>
              {this.i18n("read_less")}
            </button>
            <div className="resources">
              <a className="resources-link--pdf" download href={`/pdfs/HQLO-HOD.pdf`}>
                HQLO HOD.pdf
              </a>
              <a
                className="resources-link--pdf"
                download
                href={`/pdfs/2021-Wiercioch-new-methods.pdf`}
              >
                2021 Wiercioch new methods.pdf
              </a>
              <a
                className="resources-link--pdf"
                download
                href={`/pdfs/2020-SOLAR-Hub-and-spokes-model.pdf`}
              >
                2020 SOLAR Hub and spokes model.pdf
              </a>
              <a
                className="resources-link--pdf"
                download
                href={`/pdfs/2021-Wiercioch-Health-Outcome-descriptors-ASH.pdf`}
              >
                2021 Wiercioch Health Outcome descriptors ASH.pdf
              </a>
            </div>
          </div>
        ) : (
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.i18n("app_description_short") }} />
            <button className="description-read-more-btn" onClick={this.toggleDescription}>
              {this.i18n("read_more")}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default withCookies(DescriptionBox);
