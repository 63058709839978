import React, { Component } from "react";
import { string, func } from "prop-types";
import { Link } from "react-router-dom";
import OutcomeActions from "../../actions/outcome_actions";
import Spinner from "../spinner";
import OutcomeStore from "../../stores/outcome_store";
import connectStores from "../enhancers/connect_stores";

const storeConnectors = {
  OutcomeStore(Store) {
    return {
      getMarkerStates: Store.getOutcomeMarkerStates,
    };
  },
};

export class OutcomeMarkerStatesComponent extends Component {
  constructor(props, contenxt) {
    super(props);
    this.i18n = contenxt.i18n;
  }

  componentWillMount() {
    const { outcomeId, getMarkerStates } = this.props;
    if (!getMarkerStates(outcomeId)) {
      OutcomeActions.fetchOutcomeMarkerStates(outcomeId);
    }
  }

  render() {
    const markerStates = this.props.getMarkerStates(this.props.outcomeId);

    return markerStates ? (
      <div className="marker-states-list">
        {markerStates.isEmpty() ? (
          <span>{this.i18n("outcomes_list.no_published_marker_states")}</span>
        ) : (
          markerStates
            .map((markerState) => {
              const markerStateId = markerState.get("@id");
              const markerStateContext = markerState.get("context");
              return (
                <div className="marker-states-list-item" key={markerStateId}>
                  <Link target="_blank" to={`/marker-states/${markerStateId}`}>
                    {markerStateContext || `[${this.i18n("no_context_provided")}]`}
                  </Link>
                </div>
              );
            })
            .toList()
        )}
      </div>
    ) : (
      <Spinner />
    );
  }
}

OutcomeMarkerStatesComponent.contextTypes = { i18n: func };

OutcomeMarkerStatesComponent.propTypes = {
  outcomeId: string.isRequired,
  getMarkerStates: func.isRequired,
};

export default connectStores(OutcomeMarkerStatesComponent, OutcomeStore, storeConnectors);
