import React from "react";
import { Link } from "react-router-dom";
import { string, func, shape, bool } from "prop-types";
import { formatDate } from "../utils";

class DraftMarkerState extends React.PureComponent {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.handlePublish = this.handlePublish.bind(this);
  }

  handlePublish() {
    return this.props.onPublish(this.props.id);
  }

  render() {
    const { name, context, author, dateCreated, isBeingPublished } = this.props;

    return (
      <div className="marker-state-item draft">
        <Link className="outcome-link item-line" to={this.props.linkToDraft}>
          <div className="outcome-name">
            <span>{name}</span>
          </div>
        </Link>
        <button
          className="btn btn-primary publish"
          disabled={isBeingPublished}
          onClick={this.handlePublish}
        >
          {this.i18n("drafts.publish")}
        </button>
        <div className="item-line context">
          <span className="label">{this.i18n("context_label")}:</span>
          <span>{context}</span>
        </div>
        <div className="item-line author">
          <span className="label">{this.i18n("author")}:</span>
          <span>{author}</span>
        </div>
        <div className="item-line date-created">
          <span className="label">{this.i18n("date")}:</span>
          <span>{formatDate(dateCreated)}</span>
        </div>
      </div>
    );
  }
}

DraftMarkerState.contextTypes = { i18n: func };

DraftMarkerState.propTypes = {
  name: string.isRequired,
  context: string.isRequired,
  author: string.isRequired,
  dateCreated: string.isRequired,
  id: string.isRequired,
  onPublish: func.isRequired,
  isBeingPublished: bool.isRequired,
  linkToDraft: shape({
    pathname: string.isRequired,
    state: shape({
      fromList: bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DraftMarkerState;
