import React from "react";
import { func } from "prop-types";
import addMSSearch from "./enhancers/add_ms_search";
import OutcomeNameSearch from "./outcome_name_search";

export class SearchFormComponent extends React.Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  render() {
    const { outcomeRef, searchContextRef, handleSearch } = this.props;

    return (
      <div className="ms-container card-with-button search-form-container">
        <h2>{this.i18n("search.title")}</h2>
        <fieldset>
          <label htmlFor="outcome">{this.i18n("search.outcome_label")}</label>
          <OutcomeNameSearch
            inputId="outcome"
            inputPlaceholder="Ex: pain;"
            inputRef={outcomeRef}
            onEnterDown={handleSearch}
            searchId="searchForm"
          />
          <label htmlFor="context">{this.i18n("context_label")}</label>
          <input
            id="context"
            onKeyPress={this.handleKeyPress}
            placeholder="Ex: in arm; men;"
            ref={searchContextRef}
            type="text"
          />
        </fieldset>
        <button className="btn btn-primary" onClick={handleSearch}>
          {this.i18n("search.button_label")}
        </button>
      </div>
    );
  }
}

SearchFormComponent.contextTypes = { i18n: func };

SearchFormComponent.propTypes = {
  handleSearch: func.isRequired,
  outcomeRef: func.isRequired,
  searchContextRef: func.isRequired,
};

export default addMSSearch(SearchFormComponent);
