import alt from '../alt';
import { feedbackApi, draftsApi } from '../utils';

class AppActions {
  constructor() {
    this.generateActions(
      'feedbackSuccess',
      'feedbackError',
      'dismissAppError',
      'apiError',
      'authSuccess',
      'authError',
    );
  }

  sendFeedback(data) {
    return (dispatch) => {
      dispatch();
      return feedbackApi.send(data)
        .then(this.feedbackSuccess)
        .catch(this.feedbackError);
    };
  }

  checkAuth() {
    return (dispatch) => {
      dispatch();
      return draftsApi.fetch({})
        .then(this.authSuccess)
        .catch(this.authError);
    };
  }
}

export default alt.createActions(AppActions);

