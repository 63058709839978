import React, { Component } from "react";
import Immutable from "immutable";
import { bool, func, instanceOf } from "prop-types";
import OutcomeActions from "../../actions/outcome_actions";
import ConfirmationlDialog from "../common/confirmation_dialog";
import NotificationlDialog from "../common/notification_dialog";
import { escapeSearchHighlight } from "../../utils";

class OutcomeName extends Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = { showControls: false, showDeleteConfirmation: false };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.toggleDeleteConfirmation = this.toggleDeleteConfirmation.bind(this);
    this.toggleDeleteValidationNotification = this.toggleDeleteValidationNotification.bind(this);
    this.processDelete = this.processDelete.bind(this);
    this.showControls = this.showControls.bind(this);
    this.hideControls = this.hideControls.bind(this);
    this.hoverOptions = {
      onMouseOver: this.showControls,
      onMouseLeave: this.hideControls,
    };
  }

  componentDidUpdate(prevProps) {
    const statsArived = this.props.stats && !prevProps.stats;
    if (statsArived) {
      this.handleDelete();
    }
  }

  getDeleteValidationNotificationText() {
    const { stats } = this.props;
    const publishedMSCount = stats.get("markerStates");
    const unpublishedMSCount = stats.get("draftMarkerStates");

    return this.i18n("notifications.outcome_delete_validation_failure")
      .replace("__publishedCount__", publishedMSCount)
      .replace("__unpublishedCount__", unpublishedMSCount);
  }

  showControls() {
    this.setState({ showControls: true });
  }

  hideControls() {
    this.setState({ showControls: false });
  }

  handleEdit() {
    OutcomeActions.editOutcome(this.props.outcome);
  }

  processDelete() {
    OutcomeActions.deleteOutcome(this.props.outcome.get("@id"));
    this.toggleDeleteConfirmation();
  }

  toggleDeleteConfirmation() {
    return this.setState({ showDeleteConfirmation: !this.state.showDeleteConfirmation });
  }

  toggleDeleteValidationNotification() {
    const isNowShowing = this.state.showDeleteValidationNotification;
    return this.setState({ showDeleteValidationNotification: !isNowShowing });
  }

  handleDelete() {
    const { stats, outcome } = this.props;
    const canBeDeleted = stats && stats.valueSeq().every((v) => v === 0);

    if (canBeDeleted) {
      this.toggleDeleteConfirmation();
    } else if (stats) {
      this.toggleDeleteValidationNotification();
    } else {
      OutcomeActions.fetchOutcomeStats(outcome.get("@id"));
    }
  }

  render() {
    const { outcome, isDeleting } = this.props;
    const { showControls, showDeleteConfirmation, showDeleteValidationNotification } = this.state;
    const outcomeName = escapeSearchHighlight(outcome.get("name"));

    return (
      <div className="outcome-name" {...this.hoverOptions}>
        <span>{outcomeName}</span>
        {showControls ? (
          <span className="controls">
            <button className="delete icon" disabled={isDeleting} onClick={this.handleDelete} />
            <button className="edit icon" onClick={this.handleEdit} />
          </span>
        ) : null}
        <div className="confirmation-container">
          {showDeleteConfirmation ? (
            <ConfirmationlDialog
              className="delete-outcome"
              confirmationText={this.i18n("confirmations.delete_outcome")}
              onCancel={this.toggleDeleteConfirmation}
              onConfirm={this.processDelete}
            >
              <div className="outcome-name">{outcomeName}</div>
            </ConfirmationlDialog>
          ) : null}
          {showDeleteValidationNotification ? (
            <NotificationlDialog
              notificationText={this.getDeleteValidationNotificationText()}
              onClose={this.toggleDeleteValidationNotification}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

OutcomeName.contextTypes = {
  i18n: func,
};

OutcomeName.propTypes = {
  outcome: instanceOf(Immutable.Map).isRequired,
  isDeleting: bool.isRequired,
  stats: instanceOf(Immutable.Map),
};

OutcomeName.defaultProps = {
  stats: null,
};

export default OutcomeName;
