import React from "react";
import { string, number, func } from "prop-types";
import classNames from "classnames";

const iconWrapper = (IconComponent) => {
  const WrappedIcon = ({ name, className, defSize, onClick }) => {
    const iconClass = classNames(name, "icon", className);
    const size = {
      width: `${defSize}px`,
      height: `${defSize}px`,
    };

    return <IconComponent iconClass={iconClass} style={size} onClick={onClick} />;
  };
  WrappedIcon.defaultProps = {
    defSize: 18,
    className: "",
    onClick: null,
  };

  WrappedIcon.propTypes = {
    name: string.isRequired,
    className: string,
    defSize: number,
    onClick: func,
  };
  return WrappedIcon;
};

export default iconWrapper;
