import React from "react";
import { bool, string } from "prop-types";
import IconButton from "./common/icon_button";
import MarkerStateActions from "../actions/marker_state_actions";

class MarkerStateEditIcons extends React.Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onEdit() {
    MarkerStateActions.editBlock(this.props.blockName);
  }

  onCancel() {
    MarkerStateActions.cancelBlockEdition(this.props.blockName);
  }

  onSave() {
    MarkerStateActions.applyBlockEdition(this.props.blockName);
  }

  render() {
    const { inEditMode } = this.props;

    return inEditMode ? (
      <span className="cancel-save-icons">
        <IconButton name="save" onClick={this.onSave} />
        <IconButton name="cancel" onClick={this.onCancel} />
      </span>
    ) : (
      <IconButton name="edit" onClick={this.onEdit} />
    );
  }
}

MarkerStateEditIcons.propTypes = {
  blockName: string.isRequired,
  inEditMode: bool.isRequired,
};

export default MarkerStateEditIcons;
