import Raven from 'raven-js';
import format from 'date-fns/format';

export function parseQueryString(queryStr) {
  return queryStr.slice(1).split('&').reduce((acc, pair) => {
    if (pair.length === 0) return acc;
    const [key, val] = pair.split('=').map(decodeURIComponent);
    acc[key] = val;
    return acc;
  }, {});
}

export function queryParamsToQueryString(queryParams, ignoreParams = []) {
  return Object.keys(queryParams).reduce((acc, paramName) => {
    if (ignoreParams.indexOf(paramName) >= 0) return acc;
    const paramVal = queryParams[paramName];
    const paramStr = `${paramName}=${paramVal}`;
    return acc ? `${acc}&${paramStr}` : paramStr;
  }, '');
}

export function escapeSearchHighlight(string) {
  return string.replace(/<\/?strong>/gi, '');
}

export const emailValidityPattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+/;

export const formatDate = date => format(date, 'DD.MM.YYYY');

export const formatIso8601Date = date => format(date, 'YYYY-MM-DDTHH:mm:ssZ');

export const reportError = (error, options = {}) => {
  let msg;
  if (typeof error === 'string') {
    msg = error;
  } else if (error.message) {
    msg = `${error.message}\n\n${error.stack}`;
  } else if (error.status) {
    msg = error.status;
  } else {
    msg = 'unknown';
  }
  return Raven.captureMessage(msg, options);
};

export const generateGUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });

export const handleApiResponse = (response) => {
  const wasSuccessfull = response.status >= 200 && response.status < 300;
  // need to parse response disregarding the status: if status was successfull - parsed payload will
  // be returned. If not - parsed error data will be thrown
  return response.json()
    .then((parsed) => {
      if (wasSuccessfull) return parsed;
      throw parsed;
    })
    .catch(() => {
      if (wasSuccessfull) return response;
      throw response;
    });
};

export function getQueryString(type, params) {
  if (type === 'outcomeSearch') {
    return JSON.stringify({
      simple_query_string: {
        query: `name:(*${params}*)`,
        analyze_wildcard: true,
      },
    });
  }
  if (type === 'msSearch') {
    const clauses = ['context', 'outcome'].reduce((acc, paramName) => {
      const searchParamVal = params[paramName];
      if (searchParamVal) {
        acc.push({
          simple_query_string: {
            fields: [paramName === 'outcome' ? 'forOutcome.name' : paramName],
            query: `*${searchParamVal}*`,
            analyze_wildcard: true,
          },
        });
      }
      return acc;
    }, []);
    return JSON.stringify({ bool: { should: clauses } });
  }
}

export msApi from './ms_api';
export draftsApi from './drafts_api';
export feedbackApi from './feedback_api';
export outcomesApi from './outcomes_api';
