import React from "react";
import Immutable from "immutable";
import { instanceOf, func } from "prop-types";
import AppActions from "../actions/app_actions";

class ErrorNotification extends React.PureComponent {
  static getDismissHandler(errorText) {
    return () => AppActions.dismissAppError(errorText);
  }

  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.renderError = this.renderError.bind(this);
  }

  renderError(errorText) {
    return (
      <div className="error-notification" key={errorText}>
        <span>
          {this.i18n("error").toUpperCase()}: {errorText}
        </span>
        <button onClick={ErrorNotification.getDismissHandler(errorText)}>
          {this.i18n("actions.dismiss")}
        </button>
      </div>
    );
  }

  render() {
    const { errors } = this.props;

    return errors.isEmpty() ? null : (
      <div className="error-notification-container">{errors.map(this.renderError).toList()}</div>
    );
  }
}

ErrorNotification.propTypes = {
  errors: instanceOf(Immutable.List).isRequired,
};

ErrorNotification.contextTypes = { i18n: func };

export default ErrorNotification;
