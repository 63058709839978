import Immutable from 'immutable';

const immutableStoreConfig = {
  setState(currentState, nextState) {
    this.state = nextState;
    return this.state;
  },
  getState(currentState) {
    return currentState;
  },
  onSerialize(state) {
    return state.toJS();
  },
  onDeserialize(data) {
    return Immutable.fromJS(data);
  },
};

export default immutableStoreConfig;
