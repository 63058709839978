import Immutable from "immutable";
import React, { Component } from "react";
import { bool, instanceOf } from "prop-types";
import classNames from "classnames";
import OutcomeMarkerStates from "./outcome_marker_states";
import OutcomeName from "./outcome_name";
import OutcomeEdit from "./outcome_edit";

class OutcomeListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    return this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { outcome, isEditing, isSaving, isDeleting, stats } = this.props;
    const { expanded } = this.state;
    const itemClass = classNames("outcomes-list-item", { expanded });
    const toggleClass = classNames("icon", expanded ? "collapse" : "expand");

    return (
      <div className={itemClass}>
        {isEditing ? (
          <OutcomeEdit isSaving={isSaving} outcome={outcome} />
        ) : (
          <OutcomeName stats={stats} isDeleting={isDeleting} outcome={outcome} />
        )}
        <button className={toggleClass} onClick={this.handleToggle} />
        {expanded ? <OutcomeMarkerStates outcomeId={outcome.get("@id")} /> : null}
      </div>
    );
  }
}

OutcomeListItem.propTypes = {
  outcome: instanceOf(Immutable.Map).isRequired,
  stats: instanceOf(Immutable.Map),
  isEditing: bool.isRequired,
  isDeleting: bool.isRequired,
  isSaving: bool.isRequired,
};

OutcomeListItem.defaultProps = {
  stats: null,
};

export default OutcomeListItem;
