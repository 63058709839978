import slugify from 'slugify';
import alt from '../alt';
import { outcomesApi, msApi, getQueryString } from '../utils';
import AppActions from './app_actions';

class OutcomeActions {
  constructor() {
    this.generateActions(
      'searchSuccess',
      'searchError',
      'clearSearch',
      'fetchOutcomeMarkerStatesSuccess',
      'fetchOutcomeMarkerStatesError',
      'outcomeFetchError',
      'editOutcome',
      'changeOutcomeQuestionType',
      'updateOutcomeName',
      'saveOutcomeSuccess',
      'saveOutcomeError',
      'deleteOutcomeSuccess',
      'deleteOutcomeError',
      'fetchOutcomeStatsSuccess',
      'fetchOutcomeStatsError',
    );
  }

  search(outcomeName) {
    const query = getQueryString('outcomeSearch', outcomeName);

    return (dispatch) => {
      dispatch(outcomeName);
      return outcomesApi.search(query)
        .then(this.searchSuccess)
        .catch(this.searchError);
    };
  }

  fetchOutcomeMarkerStates(outcomeId) {
    const query = JSON.stringify({
      query_string: {
        query: `forOutcome.@id:"${outcomeId}"`,
      },
    });

    return (dispatch) => {
      dispatch(outcomeId);
      msApi.search(query)
        .then((result) => {
          const { markerStates } = result;
          return this.fetchOutcomeMarkerStatesSuccess({ outcomeId, markerStates });
        })
        .catch((err) => {
          AppActions.fetchError(err);
          return this.fetchOutcomeMarkerStatesError({ outcomeId, err });
        });
    };
  }

  saveEditingOutcome() {
    let outcomeData = alt.stores.OutcomeStore.getEditingOutcome();
    // once quesitonType prop is added in api (T763) this if statement should not be needed
    if (!(outcomeData.has('questionType'))) {
      outcomeData = outcomeData.set('questionType', 'management');
    }
    return this.saveOutcome(outcomeData.toJS());
  }

  saveOutcome(outcomeData) {
    // add backend meta data
    const withMetaData = Object.assign(outcomeData, {
      '@id': outcomeData['@id'] || slugify(outcomeData.name),
      '@type': 'Outcome',
      version: '1',
    });

    return (dispatch) => {
      dispatch(withMetaData['@id']);
      return outcomesApi.save(withMetaData)
        .then(this.saveOutcomeSuccess)
        .catch(this.saveOutcomeError);
    };
  }

  fetchOutcomeStats(outcomeId) {
    return (dispatch) => {
      dispatch(outcomeId);
      return outcomesApi.getStats(outcomeId)
        .then(stats => this.fetchOutcomeStatsSuccess({ outcomeId, stats }))
        .catch(error => this.fetchOutcomeStatsError({ outcomeId, error }));
    };
  }

  deleteOutcome(outcomeId) {
    return (dispatch) => {
      dispatch();
      return outcomesApi.delete(outcomeId)
        .then(() => this.deleteOutcomeSuccess(outcomeId))
        .catch(this.deleteOutcomeError);
    };
  }
}

export default alt.createActions(OutcomeActions);
