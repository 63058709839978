import React from "react";
import { Map } from "immutable";
import { func, bool, instanceOf, object } from "prop-types";
import connectStores from "./enhancers/connect_stores";
import MsSearchActions from "../actions/ms_search_actions";
import MsSearchStore from "../stores/ms_search_store";
import MarkerStatesList from "./marker_states_list";
import MarkerStatesPagination from "./marker_states_pagination";
import { parseQueryString } from "../utils";
import Spinner from "./spinner";

const storeConnector = {
  MsSearchStore(Store) {
    return {
      isSearching: Store.isSearching(),
      searchResults: Store.getSearchResults(),
    };
  },
};

export class SearchResultsComponent extends React.Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
  }

  componentDidMount() {
    return this.doSearch();
  }

  componentDidUpdate(prevProps) {
    return this.props.location !== prevProps.location ? this.doSearch() : undefined;
  }

  doSearch() {
    const searchParams = parseQueryString(this.props.location.search);
    MsSearchActions.search(searchParams);
  }

  render() {
    const { searchResults, isSearching } = this.props;
    const count = searchResults.get("totalCount");
    const countLabel = this.i18n(count > 1 || count === 0 ? "search.results" : "search.result");

    return isSearching ? (
      <Spinner />
    ) : (
      <div className="search-results-container marker-states-container">
        <div className="ms-container">
          <div className="results-header">
            <span>
              {this.i18n("found")}
              <span className="count"> {count} </span>
              {this.i18n(countLabel)}
            </span>
          </div>
          <MarkerStatesList markerStates={searchResults.get("markerStates")} />
        </div>
        <MarkerStatesPagination itemsCount={count} />
      </div>
    );
  }
}

SearchResultsComponent.contextTypes = { i18n: func };

SearchResultsComponent.propTypes = {
  isSearching: bool.isRequired,
  searchResults: instanceOf(Map).isRequired,
  // ReactRouter props
  location: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default connectStores(SearchResultsComponent, MsSearchStore, storeConnector);
