import React from 'react';
import { string, bool } from 'prop-types';
import Textarea from 'react-textarea-autosize';
import MarkerStateActions from '../actions/marker_state_actions';

class MarkerDetailsRatingDesc extends React.Component {
  static propTypes = {
    ratingType: string.isRequired,
    name: string.isRequired,
    label: string.isRequired,
    description: string.isRequired,
    inEditMode: bool,
  };

  static defaultProps = {
    inEditMode: false,
  };

  constructor(props) {
    super(props);
    this.updateDesc = this.updateDesc.bind(this);
  }

  updateDesc(e) {
    const { ratingType, name } = this.props;
    MarkerStateActions.updateRatingDescription({ ratingType, key: name, value: e.target.value });
  }

  render() {
    return (
      <div className="marker-rating-desc">
        <div className="desc-label"><span>{this.props.label}</span></div>
        <div className="desc">
          {this.props.inEditMode ?
            <Textarea
              minRows={2}
              maxRows={4}
              value={this.props.description}
              onChange={this.updateDesc}
            /> :
            this.props.description}
        </div>
      </div>
    );
  }
}

export default MarkerDetailsRatingDesc;
