import React, { PureComponent } from "react";
import { string, number } from "prop-types";

const STYLE = {
  display: "inline-block",
  boxSizing: "border-box",
};

class EllipsizedText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { text: props.text };
    this.onResize = this.onResize.bind(this);
    this.ref = this.ref.bind(this);
  }

  componentDidMount() {
    this.ellipsize();
    return window.addEventListener("resize", this.onResize);
  }

  componentDidUpdate() {
    return this.ellipsize();
  }

  componentWillUnmount() {
    return window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    return this.setState({
      text: this.props.text,
    });
  }

  getStyle() {
    return Object.assign({ maxHeight: this.props.height }, STYLE);
  }

  ellipsize() {
    if (this.container.scrollHeight > this.props.height) {
      const currentText = this.state.text;
      const text = currentText.split(" ");
      text.splice(-2, 2, "...");
      return this.setState({
        text: text.join(" "),
      });
    }
  }

  ref(el) {
    this.container = el;
  }

  render() {
    const { text } = this.state;
    return (
      <div className="ellipsized-text" ref={this.ref} style={this.getStyle()} title={text}>
        {text}
      </div>
    );
  }
}

EllipsizedText.propTypes = {
  text: string.isRequired,
  height: number.isRequired,
};

export default EllipsizedText;
