import React from "react";
import { func, object } from "prop-types";
import { withRouter } from "react-router-dom";
import FeedbackDialog from "./feedback_dialog";
import iluImg from "../assets/images/ilu.svg";

class Footer extends React.Component {
  constructor(props, context) {
    super(props);
    this.i18n = context.i18n;
    this.state = { showDialog: false };
    this.toggleFeedbackDialog = this.toggleFeedbackDialog.bind(this);
  }

  toggleFeedbackDialog() {
    return this.setState({ showDialog: !this.state.showDialog });
  }

  render() {
    const { location } = this.props;
    return (
      <footer>
        {location.pathname === "/" || location.pathname === "/admin" ? (
          <img alt="" src={iluImg} />
        ) : null}
        <div className="footer-container">
          <span>{this.i18n("suggestions_text")}</span>
          <button className="btn feedback" onClick={this.toggleFeedbackDialog}>
            {this.i18n("send_feedback")}
          </button>
        </div>
        <div className="footer-container">
          <div>
            <a href="https://gradepro.org/privacy" rel="noopener noreferrer" target="_blank">
              {this.i18n("privacy_policy")}
              <span> {this.i18n("and")} </span>
              {this.i18n("cookie_notice")}
            </a>
          </div>
          <div className="copyright-notice">
            <span>Copyright by </span>
            <a href="https://evidenceprime.com/" rel="noopener noreferrer" target="_blank">
              Evidence Prime Inc
            </a>
            <span>. 2016, All rights reserved</span>
          </div>
        </div>
        <div className="feedback-dialog-container">
          {this.state.showDialog ? (
            <FeedbackDialog handleClose={this.toggleFeedbackDialog} />
          ) : null}
        </div>
      </footer>
    );
  }
}

Footer.contextTypes = { i18n: func };

Footer.propTypes = {
  // ReactRouter props
  location: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(Footer);
