import React, { Component } from "react";
import { bool, func, string } from "prop-types";
import ModalDialog from "../common/modal_dialog";
import OutcomeQuestionTypeSelection from "./outcome_question_type_selection";

class NewOutcomeDialog extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      name: props.initialText,
      questionType: "management",
    };
    this.i18n = context.i18n;
    this.handleQuestionTypeChange = this.handleQuestionTypeChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(evt) {
    this.setState({ name: evt.target.value });
  }

  handleQuestionTypeChange(questionType) {
    this.setState({ questionType });
  }

  handleSubmit() {
    this.props.onAddNew(this.state);
  }

  render() {
    const { onClose, submitDisabled } = this.props;
    const { name, questionType } = this.state;

    return (
      <ModalDialog containerClass="new-outcome-dialog" onClose={onClose}>
        <div className="title-text">{this.i18n("outcomes_list.add_outcome")}</div>
        <input
          autoFocus
          name="outcome-name"
          onChange={this.handleNameChange}
          placeholder={this.i18n("outcomes_list.outcome_name")}
          type="text"
          value={name}
        />
        <div className="question-type">
          <span>{this.i18n("question_type")}: </span>
          <OutcomeQuestionTypeSelection
            onTypeSelect={this.handleQuestionTypeChange}
            selectedType={questionType}
          />
        </div>
        <button
          className="btn btn-info"
          onClick={this.handleSubmit}
          disabled={submitDisabled || name.length === 0}
        >
          {this.i18n("actions.add_to_list")}
        </button>
      </ModalDialog>
    );
  }
}

NewOutcomeDialog.contextTypes = { i18n: func };

NewOutcomeDialog.propTypes = {
  onAddNew: func.isRequired,
  onClose: func.isRequired,
  initialText: string,
  submitDisabled: bool,
};

NewOutcomeDialog.defaultProps = {
  initialText: "",
  submitDisabled: false,
};

export default NewOutcomeDialog;
